/**
 * api server
 * 普通数据api
 */
import request from '../utils/request';
import { getConfig } from './server-url-config/env';
import { message } from 'antd';
import { i18n as i } from '../i18n';
import { grpcGet, grpcPost, httpPost } from 'src/utils/httpRequest';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.gsRobot}`;

export const serviceName = serviceUrl;

function requestWrap(path, options, redirect) {
  return request(serviceUrl + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

// function testRequestWrap(path, options, redirect) {
//   return request('http://172.17.201.21:8080' + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
// }


export async function getRobotByParmaV3(page, pageSize, start, body, order) {
  return requestWrap(`/v3/robot/get?page=${page}&pagesize=${pageSize}&start=${start}`, {
    method: 'POST',
    headers: {
      order,
    },
    body,
    mode: 'cors',
  });
}

export async function getRobotManagerList(page, pageSize, start, body, order) {
  body.pageSize = pageSize;
  body.pageNumber = page;
  body.countryCode = body.robotExplain;
  delete body.robotExplain;
  body.stateCode = body.areaInfoId;
  delete body.areaInfoId;
  body.usage = body.lifeCycle;
  delete body.lifeCycle;
  body.productIdKeyword = body.productId;
  delete body.productId;
  body.aliasKeyword = body.alias;
  delete body.alias;
  body.customerKa = body.RcustomerKa;
  delete body.RcustomerKa;
  body.maintenanceRegionCode = body.businessArea;
  delete body.businessArea;
  body.isOnline = body.isOnline == 1;
  !body.isOnline && delete body.isOnline;
  let res = await grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/page`, body);
  res.datas?.forEach(u => {
    u.lifeCycle = u.robotPurpose;
    u.modelTypeCode = u.modelType
  });
  return res;
}

export async function getAllRobotByParmaV3(body = {}) {
  return requestWrap(`/v3/robot/get?page=1&pagesize=50000`, {
    method: 'POST',
    body,
    mode: 'cors',
  });
}

export async function updateRobot(id, body) {
  body.productId = id;
  body.countryCode = body.robotExplain;
  delete body.robotExplain;
  body.stateCode = body.areaInfoId;
  delete body.areaInfoId;
  body.maintenanceRegionCode = body.businessArea;
  delete body.businessArea;
  body.usage = body.lifeCycle;
  delete body.lifeCycle;
  return grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/update`, body);
}


export async function updateRobotExpandAccount(body) {
  return grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/network/expand`, body)
}

export async function getRobotBatchQuery(body) {
  return requestWrap('/robot/batchQuery', {
    body,
  });
}

export async function getRobotExceptionEvent(body) {
  return requestWrap('/robot/getRobotExceptionEvent', {
    body,
  });
}

export async function removeAlram(body) {
  return requestWrap('/robot/suppressMCUWarning', {
    body,
  });
}

export async function getVideoList(body) {
  return requestWrap('/video/list', {
    body,
  });
}

export async function getRobotEventRecordLink(body) {
  return requestWrap('/robot/getRobotEventRecordLink', {
    body,
  });
}

export async function cancelVideo(body) {
  return requestWrap('/video/cancel', {
    body,
  });
}

export async function uploadVideo(body) {
  return requestWrap('/video/upload', {
    body,
  });
}

export async function getVoiceList(body) {
  return requestWrap('/voice/list/query', {
    body,
  });
}

export async function uploadVoice(body) {
  return requestWrap('/voice/upload', {
    body,
  });
}

export async function issueVoice(body) {
  return requestWrap('/voice/issue', {
    body,
  });
}

export async function editVoice(body) {
  return requestWrap('/voice/edit', {
    body,
  });
}

export async function deleteVoice(body) {
  return requestWrap('/voice/delete', {
    body,
  });
}

export async function downloadVoice(body) {
  return requestWrap('/voice/download', {
    body,
  });
}

export async function getRobot2(body) {
  return requestWrap('/v2/robot/get?page=1&pagesize=10&start=2016', { body });
}

export async function updateCameraImageUploadSwitch(socket, body) {
  // return requestWrap('/robot/updateCameraImageUploadSwitch', {
  //   body,
  // });
  socket.emit('toggleDataFlows', body, (res) => {
    if (res.status === 'ok') {
      // message.success(res.message)
    } else {
      message.error(i(res.message))
    }
  })
}

export async function getJsonSchema(type) {
  return requestWrap(
    `/jsonSchemas/${type}`,
    {
      method: 'get',
    },
    false,
  );
}

export async function updateJsonSchema(body, type) {
  return requestWrap(
    `/jsonSchemas/${type}`,
    {
      method: 'PATCH',
      body,
    },
    false,
  );
}

export async function exportRobots(language, body) {
  body.countryCode = body.robotExplain;
  delete body.robotExplain;
  body.stateCode = body.areaInfoId;
  delete body.areaInfoId;
  body.usage = body.lifeCycle;
  delete body.lifeCycle;
  body.productIdKeyword = body.productId;
  delete body.productId;
  body.aliasKeyword = body.alias;
  delete body.alias;
  body.customerKa = body.RcustomerKa;
  delete body.RcustomerKa;
  body.maintenanceRegionCode = body.businessArea;
  delete body.businessArea;
  body.isOnline = body.isOnline == 1;
  !body.isOnline && delete body.isOnline;
  body.language = language
  return request(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/excel/export`, { method: 'POST', mode: 'cors', body, responseType: 'blob' });
}

export function doesSupportFeature(robot, featureName){
  if(!robot || typeof(robot.featureList) != 'string' || robot.featureList == '' || typeof(featureName) != 'string')
    return false;
  let featureList = JSON.parse(robot.featureList);
  if(!featureList || !Array.isArray(featureList.supportFeatureList))
    return false;
  return featureList.supportFeatureList.includes(featureName);
}

export function coverageModeFeature(robot, featureNameList){

  if(!robot || typeof(robot.featureList) != 'string' || robot.featureList == '' || !(featureNameList.length > 0))
    return false;
  let featureList = JSON.parse(robot.featureList);
  if(!featureList || !Array.isArray(featureList.supportFeatureList))
    return false;
  let res = false
    featureNameList.forEach((v) => {
      let mode = featureList.supportFeatureList.find(feature => feature === v)
      if(mode){
        res = mode.split(":")[1]
      }
    })
  return res
}

export async function getScenes() {
  return requestWrap(
    '/scence',
    {
      method: 'get',
    },
    false,
  );
}

export async function getRobotModalDetail(productId){
  return grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/detail`, {productId})
}

export async function updateRobotAlias(body){
  return grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/legacy/robot/rest/cloud/robot/update/alias`, body)
}

// 是否支持低功耗开关
export async function getModelAbility(productId) {
  return grpcGet(`${urlConfig.apiUrl.apiGatewayPrefix}/robot/v1alpha1/low-power-mode/ability?productId=${productId}`)
}
// 获取低功耗开关
export async function getModelStatus(productId){
  return grpcGet(`${urlConfig.apiUrl.apiGatewayPrefix}/robot/v1alpha1/robot-thing-model-status?thingModelStatusKey=LOW_POWER_MODE_STATUS&productId=${productId}`)
}

// 修改低功耗开关
export async function updateModelStatus(productId, status) {
  return grpcPost(`${urlConfig.apiUrl.apiGatewayPrefix}/robot/v1alpha1/low-power-mode:operate`, {productId, status})
}

//获取机器状态2.0
export async function getRobotStatusV2(param) {
  return httpPost(`${urlConfig.apiUrl.apiGatewayPrefix}/robot-reader/v1alpha1/robot/status/batch`, param, true)
}

export async function getQueryAlias(param) {
  return httpPost(`${urlConfig.apiUrl.apiGatewayPrefix}/robot-reader/remote-support/ui/robot/queryAlias`, param, true)
}

export async function getHardwaresInfo(body) {
  return httpPost(
    `${serviceUrl}/rest/cloud/robot/hardwareVersions`,
    body,
    true  
  )
}
