import { httpGet, httpPost } from 'src/utils/httpRequest';
import { getConfig } from './server-url-config/env';

const config = getConfig();
const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.gas}`;

export async function signin(body) {
  return httpPost(`${urlPrefix}/auth/signin`, body, false);
}

export async function getSecurityCodeImage() {
  return httpGet(`${urlPrefix}/captcha?options.width=180&options.height=40&options.fontsize=30`, false);
}

export async function getTokenBySSOTicket(ssoTicket) {
  return httpGet(`${urlPrefix}/sso/token?ticket=${ssoTicket}&legacy=true`, false, true);
}

export async function getLegacyToken() {
  return httpGet(`${urlPrefix}/user/legacy/token`, true, true);
}
