import { httpGet, httpPost } from 'src/utils/httpRequest';
import { getConfig } from './server-url-config/env';

const config = getConfig();
const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.cedar}`;

export async function getTenantContext() {
    return httpGet(`${urlPrefix}/tenants/context`, true);
}

export async function getPersonList(emailListString, pageSize) {
    return httpGet(`${urlPrefix}/tenants/-/persons?filter=email=in=(${emailListString})&pageSize=${pageSize}`, true);
}

export async function getPersonListByUsername(username, pageSize) {
    return httpGet(`${urlPrefix}/tenants/-/persons?filter=username=match=(${username})&pageSize=${pageSize}`, true);
}

export async function getPersonListByEmail(email, pageSize) {
    return httpGet(`${urlPrefix}/tenants/-/persons?filter=email==(${email})&pageSize=${pageSize}`, true);
}

export async function getTenants(params) {
    return httpGet(`${urlPrefix}/tenants?${params}`, true);
}

export async function getTenantUsers(tenantId, pageToken, pageSize, filter) {
    return httpGet(`${urlPrefix}/tenants/${tenantId}/persons?&pageSize=${pageSize}&view=SIMPLE${filter}${pageToken}`, true);
}

// 终端项目列表
export async function getWorkSpaceList(page, pageSize, filter, paramsString = '') {
    return httpGet(`${urlPrefix}/tenants/-/workspaces?pageSize=${pageSize}&page=${page}${filter ? `&filter=${filter}` : ''}${paramsString}`, true);
}

// 根据workspaceId 获取workspaceList
export async function getWorkSpaceById(id) {
    return httpGet(`${urlPrefix}/tenants/-/workspaces/${id}`, true);
}

// 筛选账号是不是GS
export async function filterGsTenantUsers(params) {
    return httpPost(`${urlPrefix}/tenants:filterGsTenantUsers`, params, true);
}

// 获取数据平台的权限
export async function getDataPlatformPermission({ tenantId, personId }) {
    return httpGet(`${urlPrefix}/tenants/${tenantId}/persons/${personId}/permissions?app=RobotCloudPlatform`, true)
}

// 获取数据平台的权限
export async function getPlatformPermission(params) {
  return httpPost(`${config.apiUrl.apiGatewayPrefix}/cedar/v1/person/associated/application/list`, params, true)
}

