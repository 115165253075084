/**
 * @权限 permissions
 **/
export interface Authority {
  features: string[];
  effect: string;
}
export interface IPermission {
  module: string;
  authority: Authority[];
  children?: {
    [key: string]: IPermission;
  };
}
/**
 * @已有的权限
 * @来自permissions接口
 * 可以获取到所有 可显示的key: ['accessControl', 'accessControl.roleManage', 'accessControl.userManage']
 **/
export const genPermissions = (perssions: IPermission[], arr: string[], parentModule: string) => {
  if (!perssions) return;

  perssions.map((perssion: any) => {
    const { module, authority, children } = perssion;
    if (authority && authority.length > 0) {
      authority.forEach((auth: any) => {
        const { features, effect } = auth;
        if (effect === 'ALLOW') {
          features.forEach((f: any) => {
            let key = `${module}.${f}`;
            if (f === 'view') {
              key = module;
            }

            // 如有有父级key， 使用 . 拼接
            if (parentModule) {
              key = `${parentModule}.${key}`;
            }

            arr.push(key);
          });
        }
      });
    }

    // 页面内的功能使用page.feature.feature的格式: accessControl.roleManage
    if (children && Object.keys(children).length > 0) {
      const permArr = [];
      for (const key in children) {
        if (Object.prototype.hasOwnProperty.call(children, key)) {
          const perm = children[key];
          permArr.push(perm);
        }
      }
      const parentKey = parentModule ? `${parentModule}.${module}` : module;
      genPermissions(permArr, arr, parentKey);
    }
  });
};
