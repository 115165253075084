export default {
  namespace: 'map',

  state: {
    list: [
      {
        ID: 47,
        MapUUID: '138d6646-5e4c-4a9c-962d-089c938f187b',
        ProductID: 'GS-SR-0001-0002-0000-0016',
        MapName: 'iikj',
        MapDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0016/server/f56624e1e3bf9933dbc79e10254c2b19.tar.gz',
        MapURL: null,
        Changed: 0,
        ServerMapStatus: 'NOT_EXIST',
        RobotMapStatus: 'NOT_EXIST',
        CreatedAt: '2017-08-21T11:11:08Z',
        UpdatedAt: '2018-07-10T11:54:09Z',
        MapImageDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0016/server/f56624e1e3bf9933dbc79e10254c2b19.png',
        MapImageURL: null,
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: '',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
      {
        ID: 49,
        MapUUID: '14cc041b-1421-4bac-95f6-120c63a408d7',
        ProductID: 'GS-SR-0001-0002-0004-0092',
        MapName: 'testad1c6a40-09a2-48fe-8008-2c85e6778f00',
        MapDir: null,
        MapURL: null,
        Changed: 0,
        ServerMapStatus: 'NOT_EXIST',
        RobotMapStatus: 'EXIST',
        CreatedAt: '2017-09-02T02:58:41Z',
        UpdatedAt: '2018-07-10T11:54:09Z',
        MapImageDir: null,
        MapImageURL: null,
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: 'undefined',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
      {
        ID: 52,
        MapUUID: '15c7fa92-613e-41bf-9e94-ec834c4ff092',
        ProductID: 'GS-SR-0001-0002-0000-0016',
        MapName: 'ss',
        MapDir: null,
        MapURL: null,
        Changed: 0,
        ServerMapStatus: 'NOT_EXIST',
        RobotMapStatus: 'NOT_EXIST',
        CreatedAt: '2017-11-10T01:27:40Z',
        UpdatedAt: '2018-07-10T11:54:09Z',
        MapImageDir: null,
        MapImageURL: null,
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: 'undefined',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
      {
        ID: 53,
        MapUUID: '15ee96e2-9a57-45eb-b632-34fb60b31040',
        ProductID: 'GS-SR-0001-0002-0000-0015',
        MapName: 'g',
        MapDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0015/server/03f0e4d122113ec8d5bee78b758dcabb.tar.gz',
        MapURL: 'gaussian-gz.oss-cn-shanghai.aliyuncs.com/d8a70013-0ef6-41ab-8881-caa8142b5305.tar.gz',
        Changed: 0,
        ServerMapStatus: 'EXIST',
        RobotMapStatus: 'NOT_EXIST',
        CreatedAt: '2017-11-05T14:52:44Z',
        UpdatedAt: '2018-07-12T17:03:18Z',
        MapImageDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0015/server/03f0e4d122113ec8d5bee78b758dcabb.png',
        MapImageURL: 'gaussian-png.oss-cn-shanghai.aliyuncs.com/29024c0d-41f2-42a5-b6af-c4862e7eb6ec.png',
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: '',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
      {
        ID: 54,
        MapUUID: '16b9322e-a1a0-45b0-b36d-0ed55930ead6',
        ProductID: 'GS-SR-0001-0002-0000-0016',
        MapName: 'crotest1',
        MapDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0013/server/38ecbe3a879df061436ec69f670e82cb.crotest1tar.gz',
        MapURL: 'gaussian-gz.oss-cn-shanghai.aliyuncs.com/c9fa121d-339b-4038-a6f3-1707d9f8a5e1.tar.gz',
        Changed: 0,
        ServerMapStatus: 'EXIST',
        RobotMapStatus: 'NOT_EXIST',
        CreatedAt: '2017-11-09T17:58:48Z',
        UpdatedAt: '2018-07-12T17:03:18Z',
        MapImageDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0013/server/38ecbe3a879df061436ec69f670e82cb.png',
        MapImageURL: 'gaussian-png.oss-cn-shanghai.aliyuncs.com/16561ebd-4b52-4403-aa4e-eae6c4335c0a.png',
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: 'undefined',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
      {
        ID: 55,
        MapUUID: '17586521-dc62-4016-885a-7326aadcfa7f',
        ProductID: 'GS-SR-0001-0002-0000-0016',
        MapName: 'ddde',
        MapDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0016/server/dba5baee11a6cf87da664c3aeabe4ca1.tar.gz',
        MapURL: null,
        Changed: 1,
        ServerMapStatus: 'NOT_EXIST',
        RobotMapStatus: 'NOT_EXIST',
        CreatedAt: '2017-10-18T13:51:37Z',
        UpdatedAt: '2018-07-10T11:54:09Z',
        MapImageDir:
          '/home/rcs/huhaodong_workspace/maps/GS-SR-0001-0002-0000-0016/server/dba5baee11a6cf87da664c3aeabe4ca1.png',
        MapImageURL: null,
        RobotMapImageDir: null,
        RobotMapImageURL: null,
        ModifiedDetail: 'remove map',
        RobotSnapshotChanged: 1,
        MapTaskType: 'FREE',
        MapTaskStatus: 'FREE',
      },
    ],
    currendId: undefined,
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload,
        },
      };
    },
  },
};
