export interface Role {
  id: string;
  name: string;
  title: string;
  description: string;
  code: string;
  isDelete: boolean;
  accountId: string;
  createTime: string;
  updateTime: string;
  deleteTime: string;
  policies: Policy[];
}

interface Policy {
  id: string;
  name: string;
  title: string;
  description: string;
  isDelete: boolean;
  accountId: string;
  service: string;
  createTime: string;
  updateTime: string;
  deleteTime: string;
  policyStatements: PolicyStatement[];
}

interface PolicyStatement {
  title: string;
  description: string;
  effect: PolicyStatementEffectEnum;
  actions: string[];
  resources: string[];
}

enum PolicyStatementEffectEnum {
  EFFECT_UNSPECIFIED = 'EFFECT_UNSPECIFIED',
  ALLOW = 'ALLOW',
  DENY = 'DENY',
}

export enum PredefinedRole {
  SUPER_ADMINISTRATOR = 'SUPER_ADMINISTRATOR', //超级管理员
  TENANT_ADMINISTRATOR = 'TENANT_ADMINISTRATOR', //租户管理员
  TENANT_USER = 'TENANT_USER', //租户用户
}
