import React, { Component } from 'react';
import { routerRedux, Route, Switch } from 'dva/router';
import { ConfigProvider, Spin } from 'antd';
import { connect } from 'dva';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';
import koKR from 'antd/lib/locale-provider/ko_KR';
import plPL from 'antd/lib/locale-provider/pl_PL';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import { getRouterData } from './common/router';
import { Authorized, reloadAuthorized } from './utils/Authorized';
import { getTokenBySSOTicket } from 'src/services/gas';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getAccessToken, setAccessToken, setRefreshToken } from 'utils/sessionToken';
import { getAppUrl, getSSOLoginUrl, getUrlParams } from 'utils/sso';
import { getUnit } from './services/unitSetting';
import { KBarProvider } from 'kbar';
import { CommandBar } from './components/Kbar';
import "moment/dist/locale/ja";
import { getRefreshToken } from './utils/sessionToken';
import { useMaintenanceRegionsV2, useMaintenanceRegionsV3 } from 'src/common/gobalState';
import { getMaintenanceArea } from 'services/maintenance-area';

const { ConnectedRouter } = routerRedux;
const { AuthorizedRoute } = Authorized;
const SSO_TICKET_TEXT = 'sso_ticket';
const queryClient = new QueryClient();
@connect()
class ConnectedAuthorizedRoute extends Component {
  state = {
    loading: true,
  };

  async initComponent() {
    const { dispatch } = this.props;
    // const accessToken = getAccessToken();
    // if (!accessToken) {
    const searchText = window.location.search;
    const ticket = getUrlParams(searchText, SSO_TICKET_TEXT);
    if (!ticket) {
      window.location.href = getSSOLoginUrl();
    } else {
      try {
        const res = await getTokenBySSOTicket(ticket);
        const { data, success } = res;
        if (success) {
          const { accessToken, refreshToken } = data;
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          reloadAuthorized();
          window.history.pushState({}, 'ui-cloud', `${getAppUrl()}`);

          // window.location.href = getAppUrl();
          await dispatch({
            type: 'login/fetchAccountInfo',
          });
          this.setState({ loading: false });
          this.getMeasureType();
          this.getMaintenanceAreas()
          localStorage.setItem('reach_accessToken', accessToken)
          localStorage.setItem('reach_refreshToken', refreshToken)
        }
      } catch (err) {
        dispatch({
          type: 'login/logout',
        });
      }
    }
    // } else {
    //   // 若路径含ticket则重定向, 仅用于判定子应用权限问题导致的登出行为
    //   window.location.href.indexOf("sso_ticket") > 0 && (window.location.href = getAppUrl())
    //   await dispatch({
    //     type: 'login/fetchAccountInfo',
    //   });
    //   this.setState({ loading: false });
    //   this.getMeasureType();
    //   this.getMaintenanceAreas()
    //   localStorage.setItem('reach_accessToken', getAccessToken())
    //   localStorage.setItem('reach_refreshToken', getRefreshToken())
    //   // if (process.env.NODE_ENV === 'development') {
    //   //   const { worker } = require('./mocks/browser');
    //   //   // worker.start();
    //   //   window.__mswStart = () => {
    //   //     worker.start({
    //   //       onUnhandledRequest: 'bypass',
    //   //     });
    //   //   };
    //   //   window.__mswStop = worker.stop;
    //   // }
    // }
  }

  async getMeasureType() {
    const { dispatch } = this.props;
    const resp = await getUnit();
    if (resp.code == "200" && resp.data.measuretype) {
      dispatch({
        type: "unitSetting/changeUnit",
        payload: String(resp.data.measuretype)
      });
    }
  }

  //获取运维大区
  async getMaintenanceAreas() {
    const [, setMaintenanceRegionsV2] = useMaintenanceRegionsV2();
    const [, setMaintenanceRegionsV3] = useMaintenanceRegionsV3();
    try {
      const respV2 = await getMaintenanceArea(2);
      const respV3 = await getMaintenanceArea(3);
      const { regions: maintenanceRegionsV2 } = respV2;
      const { regions: maintenanceRegionsV3 } = respV3;
      maintenanceRegionsV2.forEach(item => {
        if (maintenanceRegionsV3.some(u => u.regionCode === item.regionCode)) {
          item["isOld"] = false
        } else {
          item["isOld"] = true
        }
      })
      setMaintenanceRegionsV2(maintenanceRegionsV2)
      setMaintenanceRegionsV3(maintenanceRegionsV3)
    } catch (error) { }
  };


  componentDidMount() {
    this.initComponent();
    // 从应用中心跳转云平台url携带locale
    const search = window.location.search;
    const language = getUrlParams(search, 'locale');
    if (language) {
      localStorage.setItem("locale", language);
      window.location.href = getAppUrl();
    }
  }

  render() {
    const { loading } = this.state;
    return loading ? (
      <div
        style={{
          display: 'grid',
          width: '100vw',
          height: '100vh',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <AuthorizedRoute {...this.props} />
    );
  }
}

function RouterConfig({ history, app }) {
  const routerData = getRouterData(app);
  const BasicLayout = routerData['/'].component;

  return (
    <KBarProvider actions={[]}>
      {/* https://github.com/timc1/kbar */}
      <CommandBar />
      <ConfigProvider
        locale={
          localStorage.getItem('locale') === 'zh-CN'
            ? zhCN
            : localStorage.getItem('locale') === 'en-US'
              ? enUS
              : localStorage.getItem('locale') === 'ko'
                ? koKR
                : localStorage.getItem('locale') === 'ja-JP'
                  ? jaJP
                  : plPL
        }
      >
        <QueryClientProvider client={queryClient}>
          <ConnectedRouter history={history}>
            <Switch>
              <ConnectedAuthorizedRoute path="/" render={(props) => <BasicLayout {...props} />} />
            </Switch>
          </ConnectedRouter>
        </QueryClientProvider>
      </ConfigProvider>
    </KBarProvider>
  );
}

export default RouterConfig;
