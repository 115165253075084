import { routerRedux } from 'dva/router';
import { getAllRobotListV3, getAllRobotList } from 'services/resource';
import { getRelatedAccount } from 'services/auth';
import { message } from 'antd';

export default {
  namespace: 'robot',
  state: {
    robotList: [
      {
        ProductID: 'N/A',
      },
    ],
    modalMap: new Map(),
    currentRobotIndex: 0,
    workStatusLoading: false,
    accountList: [],
  },
  effects: {
    *allRobot(_, { call, put }) {
      try {
        const response = yield call(getAllRobotList);
        // console.log('response'.response)
        const modalMap = new Map();
        response.modelType.forEach((item) => {
          modalMap.set(item.modelType, item);
        });
        yield put({
          type: 'changeRobotList',
          payload: {
            robotList: response.data,
            modalMap,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
    *fetchRobot({ payload }, { call, put, select }) {
      // 如果和当前选择的机器人相同，那么不改变state
      const productId = yield select((state) => {
        const { robotList, currentRobotIndex } = state.robot;
        return robotList[currentRobotIndex] && robotList[currentRobotIndex].ProductID;
      });
      if (productId === payload.id) {
        return;
      }
      // 如果当前列表里有，那么改变currentRobotIndex
      const newIndex = yield select((state) => {
        const { robotList } = state.robot;
        return robotList.findIndex((r) => r.ProductID === payload.id);
      });
      if (newIndex >= 0) {
        yield put({
          type: 'changeRobotList',
          payload: {
            currentRobotIndex: newIndex,
          },
        });
        return;
      }
      // 如果都没有，那么查询服务器
      try {
        const response = yield call(getAllRobotListV3, {
          ProductID: payload.id,
        });
        if (response.data.length === 0) {
          message.error(i('机器人ID: ') + payload.id + i(' 不存在'));
          yield put(routerRedux.push('/robot'));
          return;
        }
        const modalMap = new Map();
        response.modelType.forEach((item) => {
          modalMap.set(item.modelType, item);
        });
        yield put({
          type: 'changeRobotList',
          payload: {
            robotList: response.data,
            iotSimInfo: response.iotSimInfo[0],
            currentRobotIndex: 0,
            modalMap,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
    *fetchRelateAccount({ payload }, { call, put }) {
      try {
        const response = yield call(getRelatedAccount, payload);
        // console.log(response.account);
        yield put({
          type: 'changeRobotList',
          payload: {
            accountRobotRelationList: response.data
              .filter((row) => row.accountInfoEmail)
              .filter((row) => row.accountInfoStatus),
            // accountList: response.account.filter(row => row.status !== 0),
            totalItem: response.count,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
  },
  reducers: {
    changeRobotList(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    changeWorkStatusLoading(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
