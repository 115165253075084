let userPermission = {};

let gobaleUser = {};

let tenantsContext = {};

let hasRefreshedToken = false;

let v2 = []

let v3 = []

export function useUser() {
  const setUser = (u) => {
    gobaleUser = u;
  };
  const user = _.cloneDeep(gobaleUser);
  return [user, setUser];
}

export function usePermission() {
  const setPermission = (permission) => {
    userPermission = permission;
  };
  const permission = _.cloneDeep(userPermission);
  return [permission, setPermission];
}

export function setPermissionCodeList(permission) {
  userPermission = permission;
}

export function getUserPermission() {
  return userPermission;
}

export function useTenantsContext() {
  const setTenants = (tc) => {
    tenantsContext = tc;
  };
  const tenants = _.cloneDeep(tenantsContext);
  return [tenants, setTenants];
}

export function useHasRefreshedToken() {
  const setHasRefreshedToken = (u) => {
    hasRefreshedToken = u;
  };
  return [hasRefreshedToken, setHasRefreshedToken];
}

export function useMaintenanceRegionsV2() {
  const setMaintenanceRegionsV2 = (u) => {
    v2 = u;
  };
  const maintenanceRegionsV2 = _.cloneDeep(v2);
  return [maintenanceRegionsV2, setMaintenanceRegionsV2];
}

export function useMaintenanceRegionsV3() {
  const setMaintenanceRegionsV3 = (u) => {
    v3 = u;
  };
  const maintenanceRegionsV3 = _.cloneDeep(v3);
  return [maintenanceRegionsV3, setMaintenanceRegionsV3];
}