enum PrivacyRegion {
    PRIVACY_REGION_UNSPECIFIED = 'PRIVACY_REGION_UNSPECIFIED',
    // 中国
    CHINA = 'CHINA',
    // 美国东部
    US_EAST = 'US_EAST',
    // 美国西部
    US_WEST = 'US_WEST',
    // 亚太（东京）
    ASIA_PACIFIC_NORTHEAST = 'ASIA_PACIFIC_NORTHEAST',
    // 亚太（新加坡）
    ASIA_PACIFIC_SOUTHEAST = 'ASIA_PACIFIC_SOUTHEAST',
    // 欧盟
    EUROPEAN_UNION = 'EUROPEAN_UNION',
    // 英国
    UNITED_KINGDOM = 'UNITED_KINGDOM',
}

enum State {
    STATE_UNSPECIFIED = 'STATE_UNSPECIFIED',
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

export interface Person {
    id: string,
    name: string,
    username: string,
    displayName: string,
    email: string,
    phoneNumber: string,
    gender: string,
    createTime: string,
    updateTime: string,
    deleteTime: string,
    profile: string,
    password: string,
    locale: string,
    areaCode: string,
    privacyRegion: PrivacyRegion,
    state: State,
    familyName: string,
    givenName: string,
    contactEmail: string
}

export interface Account {
    id: string,
    name: string,
    title: string,
    description: string,
    isDeleted: boolean,
    createTime: string,
    updateTime: string,
    deleteTime: string
}

export enum CustomerType {
    CUSTOMER_TYPE_UNSPECIFIED = 'CUSTOMER_TYPE_UNSPECIFIED',
    // 代理商
    AGENT = 'AGENT',
    // 终端客户
    CLIENT = 'CLIENT',
    // 租赁
    TENANCY = 'TENANCY',
    //运营商
    OPERATOR = 'OPERATOR'
}

export interface Tenant {
    id: string,
    name: string,
    displayName: string,
    region: string,
    tenantAdmin: Person,
    account: Account,
    expireTme: string,
    createTime: string,
    updateTime: string,
    deleteTime: string,
    state: State,
    abbreviation: string,
    locale: string,
    customerType: CustomerType,
    vipLevel: number,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    address5: string,
}