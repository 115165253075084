import { utils, ModeOfOperation } from 'aes-js';

const { cbc: CBC } = ModeOfOperation;

const key = 'gaussiangaussian';

function to16Bytes(keys) {
  let result = [];
  const list = utils.utf8.toBytes(keys);
  for (const item of list) {
    result.push(item);
  }
  if (result.length < 16) {
    for (let i = result.length; i < 16; i += 1) {
      result.push(0);
    }
  }
  if (result.length > 16) {
    result = result.slice(0, 16);
  }
  return Uint8Array.from(result);
}

function to16mBytes(text) {
  const result = [];
  const list = utils.utf8.toBytes(text);
  const paddingSize = 16 - (list.length % 16);
  const paddingChar = paddingSize;
  for (const item of list) {
    result.push(item);
  }
  for (let i = 0; i < paddingSize; i += 1) {
    result.push(paddingChar);
  }
  // return Uint8Array.from(result);
  return Uint8Array.from(result);
}

export function encrypt(text) {
  try {
    const encrypter = new CBC(to16Bytes(key), to16Bytes(key));
    // console.debug('aes encrypt text', text);
    const bytes = to16mBytes(text);
    // console.debug('aes encrypt bytes', bytes);
    const result = encrypter.encrypt(bytes);
    return Buffer.from(result).toString('base64');
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function decrypt(base64, binary = false) {
  try {
    const encrypter = new CBC(to16Bytes(key), to16Bytes(key));

    const text = Buffer.from(base64, 'base64');
    // console.debug('aes decrypt size', text.length);
    // console.debug('aes decrypt content', text.toString('utf-8'));
    let result = encrypter.decrypt(Uint8Array.from(text));
    result = result.slice(0, -result[result.length - 1]);
    return binary ? Buffer.from(result) : Buffer.from(result).toString('utf-8');
  } catch (e) {
    console.error(e);
    throw new Error('decrypt error');
  }
}
