import { getConfig } from '../services/server-url-config/env';

export const getAppUrl = (): string => {
  // const urlConfig = getConfig();
  // switch (urlConfig.env) {
    // case 'production':
    //   return 'https://cloud.gs-robot.com';
    // case 'staging':
    //   return 'https://cloud.staging.gs-robot.com';
    // case 'release':
    //   return 'https://cloud.release.gs-robot.com';
    // case 'dev':
    //   return 'https://cloud.dev.gs-robot.com';
    // case 'test':
    //   return 'https://cloud.test.gs-robot.com';
    // case 'local':
    //   return 'https://home.local.gs-robot.com:9527';
    // default:
    //   return 'https://cloud.test.gs-robot.com';
  // }
  // return window.location.origin + "#/";
  return window.location.origin + window.location.pathname + window.location.hash
};

export const getSSOLoginUrl = (): string => {
  const urlConfig = getConfig();
  return `${urlConfig.apiUrl.apiGatewayPrefix}/gas/api/v1alpha1/sso/login?app=${encodeURIComponent(getAppUrl())}`;
};

export const getSSOLogoutUrl = (): string => {
  const urlConfig = getConfig();
  return `${urlConfig.apiUrl.apiGatewayPrefix}/gas/api/v1alpha1/sso/logout?app=${encodeURIComponent(window.location.origin)}`;
};

export const filterXSS = (e: any) => {
  const unescape = window.unescape || window.decodeURI;
  if (!e) return e;
  for (; e !== unescape(e); ) e = unescape(e);
  for (
    let r = ['<', '>', "'", '"', '%3c', '%3e', '%27', '%22', '%253c', '%253e', '%2527', '%2522'],
      n = [
        '&#x3c;',
        '&#x3e;',
        '&#x27;',
        '&#x22;',
        '%26%23x3c%3B',
        '%26%23x3e%3B',
        '%26%23x27%3B',
        '%26%23x22%3B',
        '%2526%2523x3c%253B',
        '%2526%2523x3e%253B',
        '%2526%2523x27%253B',
        '%2526%2523x22%253B',
      ],
      a = 0;
    a < r.length;
    a++
  )
    e = e.replace(new RegExp(r[a], 'gi'), n[a]);
  return e;
};

export const getUrlParams = (url: string, name: string) => {
  const result = url.match(new RegExp(`[\?\&]${name}=([^\&\/]+)`, 'i'));
  if (result === null || result.length < 1) {
    return '';
  }
  return filterXSS(result[1]) || '';
};
