import { ActionImpl } from 'kbar'
import { message } from 'antd';
// @ts-ignore
import { getRobotByParmaV3 } from 'services/gsRobot'
import { i18n as i } from 'src/i18n';

export default function navigationRobotDetail(command: string, action: ActionImpl): ActionImpl {
  const cleanCommand = command.replace(/(^\s*)|(\s*$)/g, "").split(' ');
  const sn = cleanCommand[cleanCommand.length - 1];
  let tempAction = action
  const resetPerform = () => {
    if (sn.length <= 1) {
      message.warning(i('输入格式不正确，请重新输入'))
    }
    checkRobotPreSence(sn).then((res) => {
      if(res){
        if(res === 'new'){
          window.location.href = window.location.origin + '/#/robot/detail/' + sn
        }else{
          window.location.href = window.location.origin + '/#/robot/old/detail/' + sn
        }
      }else{
        message.warning(i('机器人不存在'))
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  if (tempAction.command) {
    tempAction.command.perform = resetPerform
  }
  return tempAction
}

async function checkRobotPreSence(sn: string) {
  try {
    const robotList = await getRobotByParmaV3(1, 10, 2010, { productId: sn, robotExplain: null }, 'robot.created_at')
    if (robotList.data.length > 0 && robotList.data[0].productId === sn) {
      const featureList = robotList.data[0].featureList ? JSON.parse(robotList.data[0].featureList) : {};
      const isNewVersion = featureList && featureList.supportFeatureList ? (featureList.supportFeatureList.includes('rxc_20211025_remote-access') || featureList.supportFeatureList.includes('remoteNewRealtimeData')) : false;
      return isNewVersion ? 'new' : 'old'
    } else {
      return false
    }
  } catch (error) {
    message.error(i('机器人列表查询失败'))
    return false
  }
}
