import RenderAuthorized from '../components/Authorized';
import { getAccessToken } from './sessionToken';

export function getAuthoritySync() {
  const { app } = window;
  const store = app && app._store; // eslint-disable-line
  if (store) {
    return store.getState().login.currentUser.granted;
  } else {
    const sessionCookie = getAccessToken();
    if (sessionCookie !== '') {
      return 'LOADING';
    } else {
      return 'GUEST';
    }
  }
}

// export function setAuthority(authority) {
//   store.dispatch({
//     type: 'global/setAuth',
//     payload: authority,
//   })
// }

let Authorized = RenderAuthorized(getAuthoritySync()); // eslint-disable-line

// Reload the rights component
export const reloadAuthorized = () => {
  Authorized = RenderAuthorized(getAuthoritySync());
};

export { Authorized };
