import { setUnit } from "src/services/unitSetting";

export default {
  namespace: "unitSetting",
  state: "1",
  reducers: {
    changeUnit(state, { payload }) {
        state = payload;
        return state;
      }
    },
  effects: {
    *updateUnit({ payload }, { call, put }) {
      try {
        const resp = yield call(setUnit, payload);
        if (resp.success) {
          yield put({
            type: "changeUnit",
            payload
          });
        }
      } catch(err) {
        console.log("updateUnit error", err);
      }
    }
  }
}
