import { useTenantsContext } from 'src/common/gobalState';
import { PredefinedRole } from 'src/services/types/cedar/role';

export const getUserRole = () => {
  const [tenants] = useTenantsContext();
  const roleList = (!!tenants && tenants.roles) || [];
  let isSuperAdmin = false;
  let isTenantAdmin = false;
  let isTenantUser = false;

  roleList.forEach((role: any) => {
    if (role.code === PredefinedRole.SUPER_ADMINISTRATOR) {
      isSuperAdmin = true;
    }
    if (role.code === PredefinedRole.TENANT_ADMINISTRATOR) {
      isTenantAdmin = true;
    }
    if (role.code === PredefinedRole.TENANT_USER) {
      isTenantUser = true;
    }
  });

  if (isSuperAdmin) {
    return PredefinedRole.SUPER_ADMINISTRATOR;
  } else {
    if (isTenantAdmin) {
      return PredefinedRole.TENANT_ADMINISTRATOR;
    } else {
      return PredefinedRole.TENANT_USER;
    }
  }
};

export const getUserCustomerType = () => {
  let type = '';
  const [tenants] = useTenantsContext();
  if (!!tenants.tenant) {
    type = tenants.tenant.customerType;
  }

  return type;
};

export const getUserTenantId = () => {
  let id = '';
  const [tenants] = useTenantsContext();
  if (!!tenants.tenant) {
    id = tenants.tenant.id;
  }

  return id;
};

export const getUserAccountId = () => {
  let id = '';
  const [tenants] = useTenantsContext();
  if (!!tenants.tenant && !!tenants.tenant.account) {
    id = tenants.tenant.account.id;
  }

  return id;
};

export const getUserPersonId = () => {
  let id = '';
  const [tenants] = useTenantsContext();
  if (!!tenants.person) {
    id = tenants.person.id;
  }

  return id;
};

export const getUsername = () => {
  let username = '';
  const [tenants] = useTenantsContext();
  if (!!tenants.person) {
    username = tenants.person.username;
  }

  return username;
};

export const getIsInternalPerson = () => {
  const [tenants]: any = useTenantsContext();
  return tenants?.internalPerson
}

export const getUserContactEmail = () => {
  const [tenants]: any = useTenantsContext();
  return tenants?.person?.contactEmail
}

export const getDisplayName = () => {
  let displayName = '';
  const [tenants]: any = useTenantsContext();
  if (!!tenants.person) {
    displayName = tenants.person.displayName;
  }
  return displayName;
};
