import { message } from 'antd';
// import moment from 'moment';

// export const ExportExcel = content => {
//   const { url, searchContents, filename, order } = content;
//   return DownloadFile(url, searchContents, filename, order);
// };

export function Message(code, error) {
  switch (code) {
    case -120:
      if (error && error.indexOf('robot_origin_code') > 0) {
        return message.warn(i('该编码已存在请重新填写'));
      } else if (error && error.indexOf('arch') > 0) {
        return message.warn(i('部位已存在请重新填写'));
      } else if (error && error.indexOf('robot_event_level_class_name') > 0) {
        return message.warn(i('该类型已存在'));
      } else if (error && error.indexOf('robot_event_class_name') > 0) {
        return message.warn(i('该类型已存在'));
      } else if (error && error.indexOf('robot_event_level_name') > 0) {
        return message.warn(i('该子类型在系统中已存在请重新填写'));
      } else if (error && error.indexOf('customer_name') > 0) {
        return message.warn(i('该客户名称已存在'));
      } else if (error && error.indexOf('customer_abbreviation') > 0) {
        return message.warn(i('该客户简称已存在'));
      } else if (error && error.indexOf('name') > 0) {
        return message.warn(i('该子类型在系统中已存在请重新填写'));
      } else {
        return message.warn(i('数据重复'));
      }
    case -33:
      return message.warn(i('请求解析失败输入参数有误'));
    case -10:
      return message.error(i('账户或密码错误'));
    case -19:
      if (error && error.indexOf('robot_origin_code') > 0) {
        return message.warn(i('该编码已存在请重新填写'));
      } else if (error && error.indexOf('name') > 0) {
        return message.warn(i('该子类型在系统中已存在请重新填写'));
      } else if (error && error.indexOf('robot_event_level_explain') > 0) {
        return message.warn(i('该等级已存在'));
      } else if (
        error &&
        (error.indexOf('email') > 0 || error.indexOf('phone') > 0) &&
        error.indexOf('Duplicate entry') > 0 &&
        error.indexOf('existing value') > 0
      ) {
        return message.warn(i('邮箱地址或手机号重复'));
      } else {
        return message.warn(i('数据重复'));
      }
    case -23:
      return message.warn(i('稍后重试')); // 数据库查询失败
    case -29:
      return message.warn(i('输入参数异常')); // 需要前端开发阶段解决
    case -60:
      return message.warn(i('数据重复')); // 主键重复
    case -40:
      return message.warn(i('参数异常')); // 主键为空
    case -80:
      return message.warn(i('参数异常')); // 主键为空
    case -100:
      return message.warn(i('数据重复')); // 主键重复
    case -150:
      return message.warn(i('请联系客服')); // state =1 + state =0 重复
    case -41:
      return message.warn(i('数据重复')); // 李康敏数据校验服务
    case -180:
      return message.warn(i('数据重复')); // 没有state
    case -20:
      return message.warn(i('请求参数异常')); // request body parse failed，需要开发阶段解决
    case -101:
      return message.warn(i('请求参数异常')); // request body parse failed，需要开发阶段解决
    case -8:
      return message.warn(i('请稍后重试')); // 数据库查询异常-可能是数据库抖动
    case -2:
      return message.warn(i('请求异常')); // 主键为空
    case -17:
      return message.warn(i('该邮箱不存在')); // 主键为空
    case -11:
      return message.warn(i('请求异常')); // product id is null
    // case -41:
    //   return message.warn(i(JSON.parse(error.validation).message));
    default:
      return message.warn(i('未知错误'));
  }
}
