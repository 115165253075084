// import { queryRobotList } from '../services/remote';
// import { getRobotCountList } from 'services/resource';
import { getCompanyList, getAllCompanyList, getAccountCountList, getSubRobot } from 'services/auth';

export default {
  namespace: 'company',
  state: {
    companyList: [],
  },
  effects: {
    *fetchCompany({ payload }, { call, put }) {
      try {
        const response = yield call(getCompanyList, payload);
        // let robotCountList;
        let accountCountList;
        const query = response.data
          .filter((item) => item.customerCode)
          .map((item) => ({ customer_code: item.customerCode }));
        if (query.length === 0) {
          accountCountList = Promise.resolve({
            count: new Proxy(
              {},
              {
                get() {
                  return 0;
                },
              },
            ),
          });
        } else {
          // robotCountList = yield call(getRobotCountList, JSON.stringify(query));
          accountCountList = yield call(getAccountCountList, JSON.stringify(query));
        }
        // const { count } = robotCountList;
        const companyList = response.data.map((item) => ({
          ...item,
          // robotCount: count[`customer_code_${item.customerCode}`],
          accountCount: accountCountList.count[`customer_code_${item.customerCode}`],
        }));
        // for (const item of companyList) {
        //   const { count } = yield call(getSubRobot, { customerCode: item.customerCode });
        //   item.robotCount = count;
        // }
        yield put({
          type: 'changeStatisticData',
          payload: {
            companyList,
            totalCompanyItem: response.count,
            currentCompanyPage: response.page,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
    *fetchAllCompany(_, { call, put }) {
      try {
        const response = yield call(getAllCompanyList);
        yield put({
          type: 'changeStatisticData',
          payload: {
            companyAllList: response.data,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
  },
  reducers: {
    changeStatisticData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
