import { routerRedux } from 'dva/router';
import { reloadAuthorized } from 'utils/Authorized';
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken
} from 'utils/sessionToken';
import { accountInfo } from 'services/account-server';
import { getDataPlatformPermission, getTenantContext } from 'src/services/cedar';
import { setPermissionCodeList, useUser, useTenantsContext, getUserPermission } from '../common/gobalState';
import { getAppUrl, getSSOLogoutUrl } from '../utils/sso';
import { getUserPersonId, getUserTenantId } from 'src/utils/tenantContext';
import { genPermissions } from 'src/utils/permission';
import { message } from 'antd';

function* fetchInfo(_, { call, put }) {
  try {
    const sessionCookie = getAccessToken();
    const res = yield call(accountInfo, sessionCookie);
    if (res && res.success) {
      const { info, data } = res;
      if (data === undefined || data.length === 0 || data.filter((item) => item.viewPermission === 1).length === 0) {
        if (!window.location.href.endsWith('/login')) {
          message.error(i('当前用不存在任何权限'));
        }
        window.location.href = getAppUrl();
        console.error('当前用户不存在任何权限');
      }
      const map = data.reduce((sum, item) => Object.assign(sum, { [item.permissionCode]: true }), {});
      // 若登陆用户为superadmin@gs-robot.com 则获取所有权限
      const makeMap = (str) => {
        const m = str.split(',').reduce((sum, item) => ({ ...sum, [item]: true }), {});
        return (v) => !!m[v];
      };
      const has = makeMap('Robot.Factory');
      if (info.email === 'superadmin@gs-robot.com') {
        setPermissionCodeList(
          new Proxy(
            {},
            {
              get(non, key) {
                return !has(key);
              },
            },
          ),
        );
      } else {
        setPermissionCodeList(map);
      }
      const [, setUser] = useUser();
      setUser({
        id: info.id,
        granted: info.granted,
        nickName: info.nickName,
        email: info.email,
        companyCode: info.companyCode,
        phoneNumber: info.phoneNumber,
        jiraAccount: info.jiraAccount, // jira 用户名
        jiraPassword: info.jiraPassword, // jira 密码
        businessArea: info.businessArea, // 运维大区
        role: info.role || [] // 角色
      });
      yield put({
        type: 'changeCurrentUser',
        payload: {
          id: info.id,
          granted: info.granted,
          nickName: info.nickName,
          email: info.email,
          companyCode: info.companyCode,
          phoneNumber: info.phoneNumber,
          jiraAccount: info.jiraAccount, // jira 用户名
          jiraPassword: info.jiraPassword, // jira 密码
          businessArea: info.businessArea, // 运维大区
        },
      });
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: true,
        },
      });
      reloadAuthorized();
    } else {
      throw Error(i('session失效'));
    }
  } catch (err) {
    console.error('fetchAccountInfo Error', err);
  }
}

function* fetchTenantsContext(_, { call, put }) {
  try {
    const res = yield call(getTenantContext);
    if (res && res.tenant && res.person && res.roles) {
      yield put({
        type: 'changeTenantsContext',
        payload: res,
      });
      const [, setTenants] = useTenantsContext();
      setTenants(res);
    } else {
      yield put({
        type: 'logout',
      });
      throw Error(i('获取租户信息失败'));
    }
  } catch (err) {
    console.error('fetch tenants context Error', err);
    // yield put(routerRedux.replace('/exception/500')); safari info接口报错，页面空白
  }
}

function* fetchDataPlatformPermission(_, { call }) {
  try {
    const personId = getUserPersonId();
    const tenantId = getUserTenantId();
    const [user] = useUser();
    const { email } = user;
    const { permissions } = yield call(getDataPlatformPermission, { personId, tenantId });
    let permissionList = [];
    if (Array.isArray(permissions)) {
      genPermissions(permissions, permissionList, "");
    }
    if (!!permissionList?.length && email !== "superadmin@gs-robot.com") {
      const cloudPermission = getUserPermission();
      const dataPlatformPermission = {};
      permissionList.forEach(v => {
        dataPlatformPermission[v] = true
      })
      const permissionObj = Object.assign({}, cloudPermission, dataPlatformPermission);
      setPermissionCodeList(permissionObj);
    }
  } catch(err) {
    console.log('err', err)
  }
}

export default {
  namespace: 'login',

  state: {
    status: false,
    session: '',
    accessToken: '',
    refreshToken: '',
    errMsg: '',
    isSecurityCodeRequired: false,
    securityCodeImg: '',
    currentUser: {
      nickName: '',
      granted: 'ADMIN',
      email: '',
      companyCode: '',
      phoneNumber: '',
      jiraAccount: '', // jira 用户名
      jiraPassword: '', // jira 密码
    },
    tenantsContext: null
  },

  effects: {
    *fetchAccountInfo(_, { call, put, select }) {
      yield fetchInfo(_, { call, put, select });
      yield fetchTenantsContext(_, { call, put });
      yield fetchDataPlatformPermission(_, { call });
    },
    *logout(_, { }) {
      setAccessToken('');
      setRefreshToken('');
      window.location.href = getSSOLogoutUrl();
    },
  },

  reducers: {
    changeErrMsg(state, { payload }) {
      return {
        ...state,
        errMsg: payload || '',
      };
    },
    changeIsSecurityCodeRequired(state, { payload }) {
      return {
        ...state,
        isSecurityCodeRequired: payload || false,
      };
    },
    changeSecurityCodeImg(state, { payload }) {
      return {
        ...state,
        securityCodeImg: payload || '',
      };
    },
    changeCurrentUser(state, { payload }) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
      };
    },
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        status: payload.status,
        errMsg: '',
      };
    },
    changeSession(state, { payload }) {
      return {
        ...state,
        session: payload.session,
        accessToken: payload.accessToken
      };
    },
    changeAccessToken(state, { payload }) {
      return {
        ...state,
        accessToken: payload.accessToken
      };
    },
    changeRefreshToken(state, { payload }) {
      return {
        ...state,
        refreshToken: payload.refreshToken
      };
    },
    changeTenantsContext(state, { payload }) {
      return {
        ...state,
        tenantsContext: payload
      };
    }
  },
};
