import { routerRedux } from 'dva/router';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    platform: 'app',
  },

  effects: {
    *gotoAdmin(_, { put }) {
      // yield put({
      //   type: 'global/changeCurrentPlatform',
      //   payload: 'admin',
      // });
      yield put(routerRedux.push('/admin'));
    },
  },

  reducers: {
    changeCurrentPlatform(state, { payload }) {
      return {
        ...state,
        platform: payload,
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter((item) => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname }) => {
        const isAdmin = pathname.split('/')[1] === 'admin';
        const isException = pathname.split('/')[1] === 'exception';
        const isSetting = pathname.split('/')[1] === 'setting';
        if (isException || isSetting) return;
        dispatch({
          type: 'changeCurrentPlatform',
          payload: isAdmin ? 'admin' : 'app',
        });
        // if (typeof window.ga !== 'undefined') {
        //   window.ga('send', 'pageview', pathname + search);
        // }
      });
    },
  },
};
