import moment from 'moment';
import { parse, stringify } from 'qs';
import { fetchList, fetchZip } from 'services/oss.js';
import { ImageShape, ViewPort, createCanvasContext2d, createTransformMatrix, PathShape } from '@youth95/letter';
import { loadMapFromData } from './map-analysis.js';
import { getDownloadInfo } from "src/services/bagLog.js"
// import reject from 'ramda/es/reject';
// import { object } from 'prop-types';
let OSS = require('ali-oss');


export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (2 * oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    // const beginTime = now.getTime() - day * oneDay;

    return [moment(now.getTime() - (7 * oneDay - 1000)), moment(now)];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function accMul(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  m += s1.split('.').length > 1 ? s1.split('.')[1].length : 0;
  m += s2.split('.').length > 1 ? s2.split('.')[1].length : 0;
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟', '万'],
  ];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData, platform) {
  let routes = Object.keys(routerData).filter((routePath) => routePath.indexOf(path) === 0 && routePath !== path);
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  let renderRoutes = renderArr.map((item) => {
    const exact = !routes.some((route) => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  renderRoutes = renderRoutes.filter((r) => !r.platform || r.platform === platform);
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export const versionIsLargeOrEqual = (v1, v2) => {
  try {
    if (!v1) {
      return false;
    }
    const v1Array = v1.replace('v', '').split('.');
    const v2Array = v2.replace('v', '').split('.');
    if (v1Array[0] > v2Array[0]) {
      return true;
    } else if (v1Array[0] < v2Array[0]) {
      return false;
    } else {
      return v1Array[1] >= v2Array[1];
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
    return false;
  }
};

// 链接两个表
export const joinTable = (fn, t0, t1) => {
  const temp = t0.map((t0item) => t1.map((t1item) => fn(t0item, t1item)));
  if (temp.length === 0) {
    return [];
  }
  return temp.reduce((sum, item) => sum.concat(item)).filter((v) => v);
};

const mapImageChacheSize = 5;
window.mapImageChache = {};
const addMapImageChache = (k, v) => {
  const keys = Object.keys(window.mapImageChache);
  if (keys.length > mapImageChacheSize) {
    delete window.mapImageChache[keys[0]];
  }
  window.mapImageChache[k] = v;
  return v;
};

// 生成多张路径图
export const createManyMaps = async (url) => {
  if (window.mapImageChache[url]) {
    return window.mapImageChache[url];
  }
  const result = [];
  const seq = url.split('/');
  do {
    result.push(seq.pop());
  } while (!seq[seq.length - 1].endsWith('aliyuncs.com') && seq.length >= 1);
  const host = seq.pop();
  const mapBucket = host.split('.')[0];
  const filename = result.reverse().join('%3F');
  const data = await fetchList(filename, mapBucket);
  const all = data.list.filter(
    (item) => item.Name.endsWith('json') || item.Name.endsWith('csv') || item.Name.endsWith('png'),
  );
  const zip = await fetchZip(
    filename,
    all.map((item) => item.Name),
    mapBucket,
  );
  const mapData = await loadMapFromData(zip);
  console.log('mapData', mapData);
  console.time('draw');
  const is = await ImageShape.fetchFromUrl(`data:image/png;base64,${mapData.mapPng.toString('base64')}`);
  const viewPort = new ViewPort({
    ctx: createCanvasContext2d(is.image.width, is.image.height),
    width: is.image.width,
    height: is.image.height,
    transformMatrix: createTransformMatrix(),
  });
  viewPort.engine.add(is);
  viewPort.engine.render();

  const print = (clear = true) => {
    viewPort.engine.render(clear);
    const url2 = viewPort.engine.ctx.canvas.toDataURL();
    // consolePrintImage(url, is.image.width, is.image.height);  // debug
    return url2;
  };
  const resolutionValues = mapData.mapJSON.mapInfo.resolution;
  const gridHeightes = mapData.mapJSON.mapInfo.gridHeight;
  const originXValues = mapData.mapJSON.mapInfo.originX;
  const originYValues = mapData.mapJSON.mapInfo.originY;
  const pathData = mapData.pathData.map((item) =>
    item.line.map((l) =>
      l.intermPoint.map((p) => [
        (p.pose.translation.x - originXValues) / resolutionValues,
        gridHeightes - (p.pose.translation.y - originYValues) / resolutionValues,
      ]),
    ),
  );

  if (pathData.length === 0) {
    return addMapImageChache(url, [
      {
        mapName: mapData.mapJSON.name,
        pathName: mapData.pathData[i].filename,
        imgSrc: print(),
      },
    ]);
  }
  const allPath = [];
  for (let i = 0; i < pathData.length; i += 1) {
    const paths = pathData[i].map((item) => new PathShape(item));
    viewPort.engine.addSome(paths);
    allPath.push({
      mapName: mapData.mapJSON.name,
      pathName: mapData.pathData[i].filename,
      imgSrc: print(),
    });
    paths.forEach((item) => item.remove());
  }
  console.timeEnd('draw');
  console.log('draw paths', allPath);
  return addMapImageChache(url, allPath);
};

export function consolePrintImage(url, width, height) {
  console.log('%c ', `padding:${height / 2}px ${width / 2}px;height:;background-image:url('${url}');`);
}

export function objValueTrim(obj) {
  const igrone = [];
  const result = _.clone(obj);
  const fn = (value) => {
    const entries = Object.entries(value);
    for (const kv of entries) {
      if (!igrone.includes(kv[0]) && typeof kv[1] === 'string') {
        // eslint-disable-next-line no-param-reassign
        value[kv[0]] = kv[1].trim();
      } else if (value[kv[0]]) {
        fn(value[kv[0]]);
      }
    }
  };
  fn(result);
  return result;
}

export function getLocalLanguage() {
  const localLang = localStorage.getItem('locale');
  switch (localLang) {
    default:
      return 'cn';
    case 'zh-CN':
      return 'cn';
    case 'en-US':
      return 'en';
    case 'ko':
      return 'ko';
    case 'pl':
      return 'pl';
    case 'ja-JP':
      return 'ja-JP';
  }
}

//新的获取语言
export function getLanguage() {
  const localLang = localStorage.getItem('locale');
  switch (localLang) {
    default:
      return 'zh-CN';
    case 'zh-CN':
      return 'zh-CN';
    case 'en-US':
      return 'en-US';
    case 'ko':
      return 'ko-KR';
    case 'pl':
      return 'pl-PL';
    case 'ja-JP':
      return 'ja-JP';
  }
}

//获取oss语言路径
export function getOssLanguage() {
  const localLang = localStorage.getItem('locale');
  switch (localLang) {
    default:
      return 'zh_cn';
    case 'zh-CN':
      return 'zh_cn';
    case 'en-US':
      return 'en_us';
    case 'ko':
      return 'ko_kr';
    case 'pl':
      return 'pl_pl';
    case 'ja-JP':
      return 'ja_jp';
  }
}

export function getFormattedDateTime(timeString) {
  let formatDate = '-';
  if (!!timeString) {
    const date = new Date(timeString),
      year = date.getFullYear().toString(),
      monthValue = date.getMonth() + 1,
      month = monthValue < 10 ? '0' + monthValue.toString() : monthValue.toString(),
      dayValue = date.getDate(),
      day = dayValue < 10 ? '0' + dayValue.toString() : dayValue.toString(),
      hourValue = date.getHours(),
      hour = hourValue < 10 ? '0' + hourValue.toString() : hourValue.toString(),
      minuteValue = date.getMinutes(),
      minute = minuteValue < 10 ? '0' + minuteValue.toString() : minuteValue.toString(),
      secondValue = date.getSeconds(),
      second = secondValue < 10 ? '0' + secondValue.toString() : secondValue.toString();

    formatDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  return formatDate;
}

export function secondTimeTransform(val) {
  let totalSeconds = val;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return hours + 'h' + minutes + 'min' + seconds + 's';
}

export const formItemLayout = {
  labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
  },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
  },
};

export const formItemModalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

export const normalizeWhitespace = (value) => {
  if (value) {
    return value.trim();
  }
  return value;
};

export const truncateString = (str, len) => {
  if (str.length <= len) return str;
  return str.substring(0, len) + '...'
}

//OSS文件下载
export  const ossFileDownload = async (recordId) => {
  const {ossPermission, records} = await getDownloadInfo(recordId)
  const { accessKeyId, accessKeySecret, bucketName, securityToken } = ossPermission;

  let client = new OSS({
    accessKeyId,
    accessKeySecret,
    region: 'oss-cn-shanghai',
    bucket: bucketName,
    stsToken: securityToken,
  });
  
    await client.get(records[0].fileObjectName, {}).then((res) => {
    // 将返回的数据通过Blob的形式转为可下载的Object。
    const newBlob = new Blob([res.content], { type: res.res.headers['content-type'] });
    // 创建标签。
    const link = document.createElement('a')
    // 将标签绑定href属性。
    link.href = window.URL.createObjectURL(newBlob)
    // 指定下载后的本地文件名称。
    link.download = records[0].fileName
    // 下载Object。
    link.click()
    // 移除绑定的URL。
    window.URL.revokeObjectURL(link.href)
  })
} 
//数据大小转换
export  const convertSize = (sizeInKB) => {
  let isNumber = Number.isFinite(sizeInKB)
  if(!isNumber || sizeInKB === 0) return sizeInKB
  const units = ['KB', 'MB', 'GB', 'TB'];
  const unitIndex = Math.floor(Math.log2(sizeInKB) / 10); // 2^10 = 1024
  return `${(sizeInKB / Math.pow(1024, unitIndex)).toFixed(2)} ${units[unitIndex]}`;
}
