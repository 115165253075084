import dva from 'dva';
import createLoading from 'dva-loading';
import 'moment/dist/locale/zh-cn';
import 'moment/dist/locale/ko';
import lodash from 'lodash';
import { reloadAuthorized } from './utils/Authorized';
import './index.less';
import { i18n, initI18n } from './i18n';
import { getConfig } from './services/server-url-config/env';
import 'antd/dist/antd.css';
import { getAppUrl } from './utils/sso';

// test -- local

// staging - pre

// release - daily

// prod - prod
const urlConfig = getConfig();

let ENV = '';
switch (urlConfig.env) {
  case 'test':
    ENV = 'local';
    break;
  case 'staging':
    ENV = 'pre';
    break;
  case 'release':
    ENV = 'daily';
    break;
  case 'production':
    ENV = 'prod';
    break;
  default:
    ENV = '';
}

console.log(`
                  _                 _
  __ _ ___    ___| | ___  _   _  __| |
 / _\` / __|  / __| |/ _ \\| | | |/ _\` |
| (_| \\__ \\ | (__| | (_) | |_| | (_| |
 \\__, |___/  \\___|_|\\___/ \\__,_|\\__,_|
 |___/
`);
console.log(`高仙云平台，当前环境 ${urlConfig.env}`);

window.i18n = i18n;
window.i = i18n;
window._ = lodash;

const createHistory = require('history').createHashHistory;

// 开发log
if (urlConfig.env === 'production') {
  window.gslogger = () => {};
  window.gswarn = () => {};
  window.console = new Proxy(
    {},
    {
      get() {
        return () => {};
      },
    },
  );
}

// 阿里云ARMS监控接入
const BrowserLogger = require('alife-logger');

const bl = BrowserLogger.singleton({
  pid: 'arv5i56jwu@7bdc3a4a46a8e98',
  appType: 'web',
  imgUrl: '',
  sendResource: true,
  enableLinkTrace: true,
  behavior: true,
});

bl.setConfig({
  environment: ENV,
});

// 1. Initialize
initI18n();
let app;
app = dva({
  history: createHistory(),
  onError(err) {
    console.log('error', err);
    window.location.href = getAppUrl();
  },
});
// 2. Plugins
app.use(createLoading());

// 3. Register global model
app.model(require('./models/global').default);
app.model(require('./models/unitSetting').default);
// 4. Router
app.router(require('./router').default);
// 5. Start
app.start('#root');

app._store; // eslint-disable-line
window.app = app;
reloadAuthorized();

window.app = app;
window.gs = {
  LAST_BUILD_TIME: process.env.LAST_BUILD_TIME,
};
