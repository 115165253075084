import { httpGet, httpPost, httpPatch, httpDelete, grpcGet, grpcPost } from 'src/utils/httpRequest';
import { getConfig } from './server-url-config/env';

const config = getConfig();
const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.dm}`;

export async function getModelType(querys) {
    return grpcGet(`${urlPrefix}/products/listModel?pageSize=${querys.size}&page=${querys.page}${querys.robotFamilyCode ? `&robotFamilyCode=${querys.robotFamilyCode}` : ""}${querys.modelType ? `&modelTypeCode=${querys.modelType}` : ""}`, true);
}

export async function getAllModelType(query) {
    return grpcGet(`${urlPrefix}/products/listModel?pageSize=1000&page=1${query ? `&robotFamilyCode=${query}` : ""}`, true);
}

export async function getRobotModel(query) {
    // let bodyContent = {};
    // if (query) {
    //     bodyContent = {
    //         robotFamilyCode: query.robotFamilyCode,
    //     };
    // }
    return grpcGet(`${urlPrefix}/products/listModel?pageSize=${query.pageSize}&page=1${query.robotFamilyCode ? `&robotFamilyCode=${query.robotFamilyCode}` : ""}`, true);
}

export async function addModelType(querys) {
    return grpcPost(`${urlPrefix}/products/model:create`, querys, true);
}
