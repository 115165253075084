export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setAccessToken(value) {
  return localStorage.setItem('accessToken', value);
}

export function setRefreshToken(value) {
  return localStorage.setItem('refreshToken', value);
}