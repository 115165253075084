export default {
  namespace: 'customerService',
  state: {
    customerInfosParams: {},
  },
  reducers: {
    storeParams(state, { payload }) {
      return {
        ...state,
        customerInfosParams: { ...payload },
      };
    },
    clearParams(state) {
      return {
        ...state,
        customerInfosParams: {},
      };
    },
  },
};
