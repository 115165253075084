/**
 * 运维大区
 */
import request from '../utils/request';
import { getConfig } from './server-url-config/env';
import { grpcGet } from 'src/utils/httpRequest';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.maintenanceArea}`;

const baseUrl = `${urlConfig.apiUrl.apiGatewayPrefix}${urlConfig.apiUrl.cedar}`

export const serviceName = serviceUrl;

function requestWrap(options, redirect) {
  return request(serviceUrl, { method: 'GET', mode: 'cors', body: {}, ...options }, redirect);
}

/**
 * 获取旧版运维大区
 */
// export async function getMaintenanceArea() {
//   return grpcGet(`${baseUrl}/refs/-/maintenanceRegions?noPaging=${true}`)
// }

// 获取新版运维大区 version取值1（旧版）取值2（新版）取值3（兼容新旧版本）
export async function getMaintenanceArea(version) {
  return grpcGet(`${baseUrl}/refs/maintenanceRegions?version=${version || 3}`)
}
