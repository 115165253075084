import { useEffect, useState } from 'react';
import reader from 'filereader-stream';
import gunzip from 'gunzip-maybe';
import concat from 'concat-stream';
import tar from 'tar-stream';
import proto from 'protobufjs/src/index';
import { message } from 'antd';
import { fetchList, serviceName } from 'services/oss.js';
import jsonDescriptor from './protos/bundle.json'; // proto定义

const root = proto.Root.fromJSON(jsonDescriptor);
const PathGraph2d = root.lookupType('fixpattern_path.PathGraph2d');

export function downLoad(url, setProgress, setStep) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onprogress = (ev) => {
      const { loaded, total } = ev;
      setProgress && setProgress({ loaded, total });
    };
    xhr.onloadend = () => {
      const blob = new Blob([xhr.response]);
      setStep && setStep('unpack');
      resolve(blob);
    };
    xhr.onerror = (err) => reject(err);
    xhr.open('GET', url);
    xhr.send();
  });
}

export function downLoad2(filename, files, mapBucket = 'gaussian-gz', setProgress) {
  const base = serviceName;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onprogress = (ev) => {
      console.log('onprogress', ev);
      const { loaded, total } = ev;
      setProgress && setProgress({ loaded, total });
    };
    xhr.onloadend = () => {
      const blob = new Blob([xhr.response]);
      resolve(blob);
    };
    xhr.onerror = (err) => reject(err);
    xhr.open('POST', `${base}/get/${mapBucket}/${filename}/light`);
    xhr.send(JSON.stringify(files));
  });
}

export async function loadMap(url, setProgress, setStep) {
  const blob = await downLoad(url, setProgress, setStep);
  const result = await loadMapFromData(blob, setStep);
  return result;
}

export function loadMapFromData(blob, setStep) {
  return new Promise((resolve, reject) => {
    const directories = {};
    const files = {};
    reader(blob)
      .pipe(gunzip())
      .pipe(tar.extract())
      .on('entry', (entry, stream, next) => {
        const name = `/${entry.name}`;
        const dir = entry.name;
        if (!directories[dir]) directories[dir] = [];
        if (name !== '/') directories[dir].push(entry);
        if (entry.type === 'directory' || entry.size > 100 * 1024) {
          stream.resume();
          next();
        }
        stream.pipe(
          concat((data) => {
            // 只保留 obstacle json csv png 四种后缀的文件
            if (
              name.endsWith('.obstacle') ||
              name.endsWith('.json') ||
              name.endsWith('.csv') ||
              name.endsWith('.png')
            ) {
              files[name] = data;
            }
            next();
          }),
        );
      })
      .on('finish', () => {
        try {
          setStep && setStep('analysis');
          const content = Object.keys(files)
            .map((k) => ({
              [k.split('/').slice(-1).join('')]: files[k],
            }))
            .reduce((sum, item) => ({ ...sum, ...item }), {});
          const mapInfo = JSON.parse(content['map.json'].toString());
          const paths = content['paths.json'] && JSON.parse(content['paths.json'].toString());
          let pathData = null;
          if (paths) {
            // 解析路径信息
            pathData = paths
              .map((item) => item.fileName)
              .map((item) => ({ filename: item, ...PathGraph2d.decode(content[item]) }));
          }
          console.log(110, content['map.obstacle']);
          const obstacle =
            content['map.obstacle'] && content['map.obstacle'].length && JSON.parse(content['map.obstacle'].toString());
          const graphPaths =
            content['graph_paths.json'] &&
            content['graph_paths.json'].length &&
            JSON.parse(content['graph_paths.json'].toString());
          const positions =
            content['positions.json'] &&
            content['positions.json'].length &&
            JSON.parse(content['positions.json'].toString());
          resolve({
            tarContent: content,
            mapPng: content[mapInfo.pngFileName],
            mapJSON: mapInfo,
            pathsJSON: paths,
            mapObstacle: obstacle,
            pathData,
            graphPaths, // 手绘路径
            positions, // 点信息
          });
          // setStep && setStep('finish');
        } catch (e) {
          reject(e);
        }
      });
  });
}

export function useMapAnalysis(url) {
  const [step, setStep] = useState('download'); // 分析阶段 download unpack analysis finish
  const [progress, setProgress] = useState(); // 下载进度
  const [, setTarContent] = useState(); // 压缩包内容
  const [MapJSON, setMapJSON] = useState(); // map基本信息
  const [MapPng, setMapPng] = useState(); // 底图信息
  const [pathsJSON, setPathsJSON] = useState(); // 路径信息
  const [mapObstacle, setMapObstacle] = useState(); // 地图障碍物信息
  const [pathData, setPathData] = useState(); // 路径信息
  const loadData = async () => {
    try {
      const info = await loadMap(url, setProgress, setStep);
      setTarContent(info.tarContent);
      setMapPng(info.mapPng);
      setMapJSON(info.mapJSON);
      setPathsJSON(info.pathsJSON);
      setMapObstacle(info.mapObstacle);
      setPathData(info.pathData);
      setStep('finish');
    } catch (e) {
      message.error(i('地图包结构错误'));
    }
  };

  useEffect(() => {
    loadData();
  }, url);

  return { progress, step, MapJSON, pathsJSON, mapObstacle, pathData, MapPng };
}

export function pngLoad(buffer) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `data:image/png;base64,${buffer.toString('base64')}`;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      resolve(imageData);
    };
    img.onerror = (e) => reject(e);
  });
}

export function loadImgFromImageData(imageData) {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);
  const image = new Image();
  console.log(ctx);
  image.src = canvas.toDataURL('image/png');
  return image;
}

// 绘制地图路径
export async function drawPaths(url) {
  const info = await loadMap(url);
  const { mapPng, pathData } = info;
  console.log(pathData);
  const bgImageData = await pngLoad(mapPng);
  return loadImgFromImageData(bgImageData);
}

export function useMapDisplay(url, setLoading) {
  const [progress, setProgress] = useState({ loaded: 0, total: 100 }); // 下载进度
  const [mapData, setMapData] = useState();
  const handler = async () => {
    setProgress({ loaded: 0, total: 100 });
    setLoading(true);
    const result = [];
    const seq = url.split('/');
    do {
      result.push(seq.pop());
    } while (!seq[seq.length - 1].endsWith('aliyuncs.com') && seq.length >= 1);
    const host = seq.pop();
    const mapBucket = host.split('.')[0];
    const filename = result.reverse().join('%3F');
    const data = await fetchList(filename, mapBucket);
    const all = data.list.filter(
      (item) =>
        item.Name.endsWith('json') ||
        item.Name.endsWith('csv') ||
        item.Name.endsWith('png') ||
        item.Name.endsWith('obstacle'),
    );
    const zip = await downLoad2(
      filename,
      all.map((item) => item.Name),
      mapBucket,
      setProgress,
    );
    setMapData(await loadMapFromData(zip));
  };

  useEffect(() => {
    handler();
  }, [url]);

  return { progress, mapData };
}

export function generateSiteList(siteList){
  return siteList.map(v => ({
    siteId: v?.id,
    title: v?.displayName,
    spaceId: v?.spaceId,
    state: v?.state,
    name: v?.spaceName,
    type: "SITE"
  }))
}

export function generateSpaceList(spaceList) {
  return spaceList.map(v => ({
    siteId: v?.siteId,
    title: v?.displayName,
    spaceId: v?.id,
    state: v?.state,
    name: v?.name,
    type: v?.type,
    underground: v?.underground
  }))
}
