/**
 * api server
 * 普通数据api
 */
import request from '../utils/gsrequest';
import { getConfig } from './server-url-config/env';
import { grpcPost, grpcGet, httpPost } from 'src/utils/httpRequest';
import { getLocalLanguage } from 'src/utils/utils';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.resource}`;
const baseUrl = `${urlConfig.apiUrl.prefix}/robot/rest/cloud`
export const serviceName = serviceUrl;
const countryUrl = getConfig().apiUrl.apiGatewayPrefix + '/coma/v1alpha1/countries';
const domain = urlConfig.apiUrl.apiGatewayPrefix;

function requestWrap(path, options, redirect) {
  return request(serviceUrl + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

export async function getAllRobotList(body = {}) {
  return requestWrap(`/v2/robot/get?start=2010&pagesize=5000&page=1`, { body });
}

// 已废弃接口
export async function getAllRobotListV3(body = {}) {
  return requestWrap(`/v3/robot/get/mu?start=2010&pagesize=5000&page=1`, { body });
}

export async function getAllCountryList(query) {
  let bodyContent = {};
  if (query) {
    bodyContent = {
      type: query.type,
      parentId: query.parentId,
    };
  }
  const lang = decodeURIComponent(getLocalLanguage() == 'cn' ? 'zh-CN' : 'en-US');
  if(bodyContent.type != 2){
    let res = await grpcGet(countryUrl + '?noPaging=true&lang=' + lang);
    return {data: res.countries?.map(u => ({id: u.countryCode, name: u.displayName, englishName: u.displayName}))};
  }
  if(bodyContent.parentId){
    try {
      let res = await grpcGet(countryUrl + '/' + bodyContent.parentId + '/states?noPaging=true&lang=' + lang);
      return {data: res.states.map(u => ({id: u.stateCode, name: u.displayName, englishName: u.displayName}))};
    } catch {
      return [];
    }
  }
  return {data: []};
}

export async function getFirmwareByParma(body) {
  return grpcPost(`${baseUrl}/firmware/latest`, body)
}

export async function getV3MuRobotList(querys) {
  return requestWrap(`/v3/robot/get/mu?pagesize=5000&page=1`, {
    method: 'POST',
    headers: {
      order: 'robot.created_at',
    },
    body: querys,
    mode: 'cors',
  });
}

export async function getRobotView(body) {
  let data = await grpcPost(`${baseUrl}/robot/view`, body);
  if (!!data) {
    data.robotExplain = data.countryCode;
    data.areaInfoId = data.stateCode;
    data.countryCode = data.address;
    data.businessArea = data.maintenanceRegionCode;
    data.isOnline = data.isOnline ? 1 : 0;
    data.modelTypeCode = data.modelType;
  }
  return data;
}
/**
 * 重点关注机器人
 * @param {*} showDetails 是否显示详细信息，0不显示，1显示
 * @returns 
 */
export function getFocusRobots(showDetails) {
  return httpPost(`${domain}/gs-robot/rest/v2/web/robot/focus/queryAllFocused`, {
    showDetails
  }, true)
}

export function focusOnRobots(productId) {
  return httpPost(`${domain}/bot/v1alpha1/robot:focus`, { productId }, true)
}

export function unFocusOnRobots(productId) {
  return httpPost(`${domain}/bot/v1alpha1/robot:unFocus`, { productId }, true)
}
