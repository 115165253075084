/**
 * api server
 * 普通数据api
 */
import { httpPost } from 'src/utils/httpRequest';
import request from '../utils/gsrequest';
import { getConfig } from './server-url-config/env';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.auth}`;
const resourceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.resource}`
const cedarPrefix = `${urlConfig.apiUrl.apiGatewayPrefix}${urlConfig.apiUrl.cedar}`

export const serviceName = serviceUrl;

async function requestWrap(path, options, redirect) {
  return request(serviceUrl + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

async function requestWrap2(path, options, redirect) {
  return request( resourceUrl + path, { method: 'POST', mode: 'cors', body: {}, ...options }, redirect);
}

export const authRequest = requestWrap;

export async function getCompanyList(querys) {
  const query = querys.company;
  return requestWrap2(
    `/customer/get?order=created_at&start=2010&pagesize=${querys.size || 10}&page=${querys.page || 1}`,
    {
      body: {
        ...query,
        ka: querys.ka,
        businessType: querys.businessType,
        businessTree: querys.businessTree,
      },
    },
  );
}

export async function getAllCompanyList(querys) {
  return requestWrap2(`/customer/get?order=created_at&start=2010&pagesize=5000&page=1`, {
    body: {
      ...querys,
    },
  });
}

export async function addCompany(querys) {
  return requestWrap2(`/customer/create`, { body: querys });
}

export async function editCompany(querys) {
  return requestWrap2(`/customer/update/${querys.id}`, {
    method: 'POST',
    body: querys,
  });
}

export async function deleteCompany(id) {
  return requestWrap2(`/customer/${id}`, {
    method: 'DELETE',
    body: { status: 0 },
  });
}

export async function getSubRobot(querys) {
  return requestWrap(`/customer/sub/robot/${querys.customerCode}`, {
    method: 'GET',
    headers: {},
    // body: querys,
    mode: 'cors',
  });
}

export async function getAccountCountList(count = {}) {
  return requestWrap(`/account_info/count/list`, { body: {}, headers: { count } });
}

export async function accountInfoFroce(payload) {
  return requestWrap(`/account/info/force`, { method: 'GET', ...payload });
}

export function fetchAllPermission() {
  return requestWrap('/permission_info/get?pagesize=100000&page=1');
}

export function createRobotPramsPermission(permissionCode, ids, codes) {
  return requestWrap(`/permission_info/add`, {
    body: {
      permissionCode,
      methodPermission: 1,
      permissionLevel: -1,
      permissionDescription: JSON.stringify(ids),
      permissionRemark: JSON.stringify(codes),
      state: 1,
    },
  });
}

export function updateRobotPramsPermission(id, ids, codes) {
  return requestWrap(`/permission_info/update/${id}`, {
    body: {
      id,
      permissionDescription: JSON.stringify(ids),
      permissionRemark: JSON.stringify(codes),
    },
  });
}

export function fetchAllViewPermission() {
  return requestWrap('/permission_info/get?pagesize=100000&page=1', {
    body: { viewPermission: 1 },
  });
}

export function fetchAllServicePermission(permissionLevel) {
  return requestWrap('/permission_info/get?pagesize=100000&page=1', {
    body: { methodPermission: 1, permissionLevel },
  });
}

export function fetchRolePermissionRelationByRoleCode(roleCode) {
  return requestWrap('/role_permission_relation/get?pagesize=1000000', {
    body: { roleCode },
    headers: { s: true },
  });
}

export function fetchRolePermissionRelations() {
  return requestWrap('/role_permission_relation/get?pagesize=1000000');
}

export function fetchRoleAccountRelations() {
  return requestWrap('/account_role_relation/get?pagesize=1000000');
}

export function fetchRoleAccountRelation(content) {
  return requestWrap('/account_role_relation/get?pagesize=1000000', {
    body: content,
  });
}

export function fetchRoleAccount(content) {
  return requestWrap('/v2/account_role_relation/get?pagesize=1000000', {
    body: content,
  });
}

export function createRole(role) {
  return requestWrap('/v2/role_info/add', {
    method: 'POST',
    body: role,
    headers: {},
    // headers: { strict: true },
  });
}

export function updateRoleName(id, roleName) {
  return requestWrap(`/role_info/update/${id}`, { method: 'POST', body: { roleName } });
}

export function removeRole(role) {
  return requestWrap(`/role_info/${role.id}`, { method: 'DELETE' });
}

export async function createRolePermissionRelations(relations) {
  // for (const relation of relations) {
  //   /* eslint-disable */
  //   await requestWrap('/role_permission_relation/create', { body: relation });
  // }
  if (relations.length === 0) {
    return;
  }
  await requestWrap('/v1/role_permission_relation/collection/create', {
    method: 'PUT',
    body: relations,
  });
}

export async function removeRolePermissionRelations(relationIds) {
  // for (const id of relationIds) {
  //   /* eslint-disable */
  //   await requestWrap(`/role_permission_relation/${id}`, { method: 'DELETE' });
  // }
  await requestWrap('/v1/role_permission_relation/list/remove', {
    method: 'PUT',
    body: relationIds,
  });
}

export async function removeAccountRobotRelations(relationIds) {
  await requestWrap('/v1/account_robot_relation/list/remove', {
    method: 'PUT',
    body: relationIds,
  });
}

export async function createAccountRobotRelations(relations) {
  await requestWrap('/v1/account_robot_relation/collection/create', {
    method: 'PUT',
    body: relations,
  });
}

export async function getAccountList(querys) {
  const result = await requestWrap(`/account_info/get?start=2010&pagesize=${querys.size}&page=${querys.page}`, {
    body: {
      email: querys.accountInfoEmail,
      phoneNumber: querys.accountInfoPhoneNumber,
    },
  });
  result.data = result.data.filter((item) => item.email !== 'superadmin@gs-robot.com');
  return result;
}

export async function getAllAccounts() {
  const result = await requestWrap('/account_info/get?start=2010&pagesize=10000000&page=1');
  result.data = result.data.filter((item) => item.email !== 'superadmin@gs-robot.com');
  return result;
}

export async function modifyAccountJira(id, username, password) {
  return requestWrap(`/account_info/update/${id}`, {
    body: { id, jiraAccount: username, jiraPassword: password },
    method: 'POST',
  });
}

export async function modifyAccountBusinessArea(id, businessArea) {
  return requestWrap(`/account_info/update/${id}`, {
    body: { id, businessArea },
    method: 'POST',
  });
}

export async function fetchAccountByEmail(email) {
  return requestWrap(`/account_info/get`, { body: { email } });
}

export async function getRelatedRobot(querys) {
  // console.log(this.props);
  const result = await requestWrap(`/v2/account_robot_relation/get?pagesize=10000&page=${querys.currentPage}`, {
    headers: {
      s: 'true',
    },
    body: { accountId: querys.accountId },
  });
  result.data = result.data.filter((item) => item.accountInfoEmail !== 'superadmin@gs-robot.com');
  return result;
}

// export async function getRelatedAccount(querys) {
//   // console.log(this.props);
//   const result = await requestWrap(
//     `/v2/account_robot_relation/get?pagesize=20&page=${querys.currentPage}`,
//     {
//       headers: {
//         s: 'true',
//       },
//       body: { robotCode: querys.robotId },
//     }
//   );
//   result.data = result.data.filter(item => item.accountInfoEmail !== 'superadmin@gs-robot.com');
//   return result;
// }
// 根据productId获取绑定账号
export async function getBindAccountByProductId(query) {
  let bodyContent = {};
  if (query) {
    bodyContent = {
      robotCode: query.productId,
      pageSize: 3000,
    };
  }
  return requestWrap(`/account_robot_relation/get?pageSize=${query.pageSize}&page=1`, {
    headers: {
      s: 'true',
    },
    body: bodyContent,
  });
}

export async function getRelatedAccount(querys) {
  // console.log(this.props);
  const result = await requestWrap(`/v2/account_robot_relation/get?pagesize=${querys.size}&page=${querys.page}`, {
    headers: {
      order: 'account_robot_relation.created_at',
    },
    body: {
      robotCode: querys.robotId,
      accountEmail: querys.accountEmail,
    },
  });
  const r = result.data.length; // 原始长度
  result.data = result.data.filter((item) => item.accountInfoEmail !== 'superadmin@gs-robot.com'); // 过滤可能存在的superadmin@gs-robot.com记录
  result.count = r !== result.data.length ? result.count - (r - result.data.length) : result.count; // 更正 count字段
  return result;
}

export function queryRelatedAccount(body){
  return httpPost(`${cedarPrefix}/robots/bindings/persons`, body, true)
}

export async function createRelatedAccount(query) {
  return requestWrap(`/account_robot_relation/create`, {
    method: 'POST',
    body: query,
  });
}

export async function relationAccountInfo(robotCode) {
  const result = await requestWrap(`/relation/account/${robotCode}`, {
    method: 'GET',
  });
  result.data = result.data.filter((item) => item.email !== 'superadmin@gs-robot.com');
  return result;
}

export async function deleteRelatedAccount(id) {
  return requestWrap(`/account_robot_relation/${id}`, {
    method: 'DELETE',
  });
}

export async function editRelatedAccount(querys) {
  return requestWrap(`/account_robot_relation/update/${querys.id}`, {
    method: 'POST',
    body: querys,
  });
}

export async function deleteAccount(id) {
  return requestWrap(`/account_info/${id}`, {
    method: 'DELETE',
    // body: { status: 0 },
  });
}

export async function updateAccountInfo(querys) {
  return requestWrap(`/account_info/${querys.accountInfoId}`, {
    method: 'PUT',
    body: querys,
  });
}

export async function updateAccountInfoRoleRelation(querys) {
  return requestWrap(`/account_role_relation/${querys.accountRoleRelationId}`, {
    method: 'PUT',
    body: querys,
  });
}

export async function createAccountInfoRoleRelation(querys) {
  return requestWrap(`/account_role_relation/create`, {
    method: 'POST',
    body: querys,
  });
}

export async function fetchAllAccountRobot(accountEmail) {
  const result = await requestWrap('/account_robot_relation/get?page=1&pagesize=10000000', {
    method: 'POST',
    body: { accountEmail },
  });
  result.data = result.data.filter((item) => item.accountEmail !== 'superadmin@gs-robot.com');
  return result;
}

/**
 * 创建用户
 * @param companyCode 企业编码
 * @param roleCode  角色编码
 * @param email 用户邮箱
 * @param phone 用户手机
 * @param notify  通知方式 为一个数组 ["email"] 表示用户只勾选了邮箱，["phone"] 表示用户仅勾选了电话，["email","phone"]表示全勾选了
 * @param robots  机器人列表 一个包含用户所选机器人SN号的字符串数组
 * @returns {Promise<void>}
 */
export async function accountSignUp({
  emailNumber,
  phoneNumber,
  areaCode,
  lang,
  role,
  customer,
  notify,
  robotCodeList,
} = {}) {
  return requestWrap('/account/sign/up', {
    method: 'POST',
    body: {
      account: {
        email: emailNumber,
        phoneNumber,
        lang,
        areaCode: areaCode || 86,
        customerCode: customer,
        emailActivated: notify.includes('email') ? 1 : 0,
        phoneActivated: notify.includes('phone') ? 1 : 0,
      },
      robotCodeList,
      roleCodeList: [role],
    },
  });
}
