// import { queryRobotList } from '../services/remote';
import { getAccountList, getRelatedRobot } from 'services/auth';

export default {
  namespace: 'account',
  state: {
    accountList: [],
  },
  effects: {
    *fetchAccount({ payload }, { call, put }) {
      try {
        const response = yield call(getAccountList, payload);
        yield put({
          type: 'changeStatisticData',
          payload: {
            accountList: response.data,
            totalItem: response.count,
            currentPage: response.page,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
    *fetchRelateRobot({ payload }, { call, put }) {
      try {
        const response = yield call(getRelatedRobot, payload);
        // console.log(response);
        yield put({
          type: 'changeStatisticData',
          payload: {
            robotRelationList: response.data,
            // robotNameList: response.robot,
            totalRelationRobotItem: response.count,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
  },
  reducers: {
    changeStatisticData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
