import { getModelType } from 'services/modelType';

export default {
  namespace: 'product',
  state: {
    productList: [],
  },
  effects: {
    *fetchProduct({ payload }, { call, put }) {
      try {
        const response = yield call(getModelType, payload);
        const productList = response.data;
        console.log('24', productList);
        yield put({
          type: 'changeStatisticData',
          payload: {
            productList,
            totalProductItem: response.count,
            currentProductPage: response.page,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
  },
  reducers: {
    changeStatisticData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
