import { message } from 'antd';
import request from '../utils/gsrequest';

function requestVerificationCode(params) {
  const { email } = params;
  return request(`/auth/password/forget/${email}`, {
    method: 'GET',
  });
}

function resetPassword(params) {
  return request(`/auth/password/reset`, {
    method: 'POST',
    body: params,
    mode: 'cors',
  });
}

export default {
  namespace: 'forgetpass',
  state: {
    currentStep: 0,
    currentUser: undefined,
    errMsg: '',
  },

  effects: {
    *reqVeriCodeAndForard({ payload }, { call, put }) {
      try {
        const response = yield call(requestVerificationCode, payload);
        if (response && response.success) {
          yield put({
            type: 'forwardCurrentStep',
            payload,
          });
        }
      } catch (err) {
        yield put({
          type: 'changeErrMsg',
          payload: i(JSON.parse(err.message).msg),
        });
      }
    },
    *reqVeriCode({ payload }, { call, put }) {
      try {
        const response = yield call(requestVerificationCode, payload);
        if (response && response.success) {
          return;
        }
      } catch (err) {
        yield put({
          type: 'changeErrMsg',
          payload: i(JSON.parse(err.message).msg),
        });
      }
    },
    *reqResetPass({ payload, redirect = true }, { call, put, select }) {
      try {
        const currentUser = yield select((state) => state.forgetpass.currentUser);
        if (!currentUser) {
          return;
        }
        const response = yield call(resetPassword, {
          email: currentUser,
          validCode: payload.code,
          password: payload.password,
        });
        if (response && response.success) {
          yield put({
            type: 'changeErrMsg',
            payload: '',
          });
          message.success(i('密码重置成功'));
          if (redirect) {
            yield put({
              type: 'login/logout',
            });
          }
        } else {
          yield put({
            type: 'changeErrMsg',
            payload: i(response.msg),
          });
        }
      } catch (err) {
        yield put({
          type: 'changeErrMsg',
          payload: i(JSON.parse(err.message).msg),
        });
      }
    },
  },
  reducers: {
    changeErrMsg(state, { payload }) {
      return {
        ...state,
        errMsg: payload || '',
      };
    },
    setCurrentUser(state, { payload }) {
      return {
        ...state,
        currentUser: payload.currentUser,
      };
    },
    resetCurrentStep(state) {
      return {
        ...state,
        currentStep: 0,
        errMsg: '',
      };
    },
    forwardCurrentStep(state, { payload }) {
      return {
        ...state,
        currentStep: state.currentStep + 1,
        currentUser: payload.email,
        errMsg: '',
      };
    },
  },
};
