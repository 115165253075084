function requestWrap(params) {
  return params.loading;
}

export default {
  namespace: 'download',
  state: {},
  effects: {
    *fetchLoding({ payload }, { call, put }) {
      try {
        const response = yield call(requestWrap, payload);
        yield put({
          type: 'changeStatisticData',
          payload: {
            loading: response,
          },
        });
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
  },
  reducers: {
    changeStatisticData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
