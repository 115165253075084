import request from '../utils/request';
import { getConfig } from './server-url-config/env';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.cloudPrefix}${urlConfig.apiUrl.oss}`;

export const serviceName = serviceUrl;

export async function fetchList(filename, mapBucket = 'gaussian-gz') {
  return request(`${urlConfig.apiUrl.cloudPrefix}${urlConfig.apiUrl.oss}/get/${mapBucket}/${filename}/file/list`, { method: 'GET', time: 60 * 1000 }, false, true);
}

export async function fetchZip(filename, files, mapBucket = 'gaussian-gz') {
  return request(`${urlConfig.apiUrl.cloudPrefix}${urlConfig.apiUrl.oss}/get/${mapBucket}/${filename}/light`, { method: 'POST', mode: 'cors', body: files, time: 60 * 1000 }, false, true);
}

export async function fetchFile(filename, file, mapBucket = 'gaussian-gz') {
  return request(`${urlConfig.apiUrl.cloudPrefix}${urlConfig.apiUrl.oss}/get/${mapBucket}/${filename}/${file}/list`, { method: 'GET', time: 60 * 1000 }, false, true);
}
