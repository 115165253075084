import axios from 'axios';
import qs from "qs";
import { getConfig } from '../services/server-url-config/env';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '../utils/sessionToken';

const config = getConfig();
const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.gas}`;
const refreshTokenUrl = `${urlPrefix}/auth/token`;
let hasLogouted = false;

export function resetLoginStatus(): void {
  hasLogouted = false;
}

function getAxiosConfig(isNeedToken: boolean, withCredentials?: boolean): any {
  let config = undefined;
  if (isNeedToken) {
    const accessToken = getAccessToken();
    config = {
      headers: {
        Authorization:
          `Bearer ${accessToken}`
      },
      withCredentials: !!withCredentials
    };
  } else {
    config = {
      withCredentials: !!withCredentials
    };
  }
  return config;
}

export async function httpGet(url: string, isNeedToken: boolean, withCredentials?: boolean): Promise<any> {
  try {
    const resp: any = await axios.get(url, getAxiosConfig(isNeedToken, withCredentials));
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response = {} } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('GET', url, null);
    } else {
      throw error;
    }
  }
}

export async function qsGet(url: string, body?: any, withCredentials?: boolean): Promise<any> {
  try {
    const paramConfig = body ? { params: body, paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: "repeat" }) } : {};
    const resp: any = await axios(
      Object.assign({ method: "get", url, }, paramConfig, getAxiosConfig(true, withCredentials))
    );
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response = {} } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('GET', url, null);
    } else {
      throw new Error(
        error?.response?.data?.details?.length
          ? error?.response?.data?.details[0]?.reason
          : ""
      );
    }
  }
}


export async function httpPost(url: string, body: any, isNeedToken: boolean): Promise<any> {
  try {
    const resp = await axios.post(url, body, getAxiosConfig(isNeedToken));
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response = {} } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('POST', url, body);
    } else {
      throw error;
    }
  }
}

export async function httpPut(url: string, body: any, isNeedToken: boolean): Promise<any> {
  try {
    const resp = await axios.put(url, body, getAxiosConfig(isNeedToken));
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('PUT', url, body);
    } else {
      throw error;
    }
  }
}


export async function httpPatch(url: string, body: any, isNeedToken: boolean): Promise<any> {
  try {
    const resp = await axios.patch(url, body, getAxiosConfig(isNeedToken));
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response = {} } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('PATCH', url, body);
    } else {
      throw error;
    }
  }
}

export async function httpDelete(url: string, isNeedToken: boolean): Promise<any> {
  try {
    const resp: any = await axios.delete(url, getAxiosConfig(isNeedToken));
    const { data } = resp;
    return data;
  } catch (error: any) {
    const { response = {} } = error;
    if (response.status === 401) {
      return refreshTokenAndRequestAgain('DELETE', url, null);
    } else {
      throw error;
    }
  }
}

async function refreshTokenAndRequestAgain(method: string, url: string, body: any): Promise<any> {
  try {
    const resp: any = await axios.post(refreshTokenUrl, { refreshToken: getRefreshToken() });
    const { data } = resp;
    const { accessToken, refreshToken } = data.data;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    const header = { headers: { Authorization: `Bearer ${accessToken}` } };

    switch (method) {
      case 'GET':
        const respGet = await axios.get(url, header);
        return respGet.data;
      case 'POST':
        const respPost = await axios.post(url, body, header);
        return respPost.data;
      case 'PUT':
        const respPut = await axios.put(url, body, header);
        return respPut.data;
      case 'PATCH':
        const respPatch = await axios.patch(url, body, header);
        return respPatch.data;
      case 'DELETE':
        const respDelete = await axios.delete(url, header);
        return respDelete.data;
    }
  } catch (error: any) {
    if (!hasLogouted) {
      const { app } = window as any;
      const store = app && app._store;
      const { dispatch } = store;
      hasLogouted = true;
      dispatch({
        type: 'login/logout',
      });
    }
    return;
  }
}
/**
 * 发起get请求，要在try里调用，catch到的字符串是错误码
 * @param url 请求URL
 */
export async function grpcGet(url: string){
  try{
    return await httpGet(url, true);
  }
  catch(err){
    throw new Error((err as any).response?.data?.details?.length ? (err as any).response?.data?.details[0]?.reason : '')
  }
}
/**
 * 发起post请求，要在try里调用，catch到的字符串是错误码
 * @param url 请求URL
 * @param body 请求body
 */
export async function grpcPost(url: string, body: any){
  try{
    return await httpPost(url, body, true);
  }
  catch(err){
    throw new Error((err as any).response?.data?.details?.length ? (err as any).response?.data?.details[0]?.reason : '')
  }
}
