import { getConfig } from '../services/server-url-config/env';
import enUS from './en-US.json';
import zhCN from './zh-CN.json';
import koKR from './ko-KR.json';
import plPL from './pl-PL.json';
import jaJP from "./ja-JP.json";

export const initI18n = () => {
  let locale = localStorage.getItem('locale');
  console.log('当前浏览器语言环境', navigator.language);
  const lang = navigator.language;
  if (locale == null) {
    if (/\ben\b/.test(lang)) {
      locale = 'en-US';
    } else if (/\bzh\b/.test(lang)) {
      locale = 'zh-CN';
    } else if (/\bko\b/.test(lang)) {
      locale = 'ko';
    } else if (/\bpl\b/.test(lang)) {
      locale = 'pl';
    } else if (/\bja\b/.test(lang)) {
      locale = 'ja-JP';
    } else {
      locale = 'zh-CN';
    }
  }
  localStorage.setItem('locale', locale);
  window.appLocale = {};
  // 语言包放本地
  if (locale === 'en-US') {
    window.appLocale = enUS;
  } else if (locale === 'ko') {
    window.appLocale = koKR;
  } else if (locale === 'pl') {
    window.appLocale = plPL;
  } else if (locale === 'ja-JP') {
    window.appLocale = jaJP;
  } else {
    window.appLocale = zhCN;
  }
};

export const switchLocale = (langValue) => {
  if (langValue === 'cn' || langValue === 'zh-CN') {
    localStorage.setItem('locale', 'zh-CN');
  } else if (langValue === 'ko') {
    localStorage.setItem('locale', 'ko');
  } else if (langValue === 'pl') {
    localStorage.setItem('locale', 'pl');
  } else if (langValue === 'ja-JP') {
    localStorage.setItem('locale', 'ja-JP');
  } else {
    localStorage.setItem('locale', 'en-US');
  }
  if (window) {
    window.location.reload();
  }
};

export const i18n = (key, parameter) => {
  try {
    let value = window.appLocale;
    value = value[key];
    if (!value) {
      return key;
    }
    if (typeof parameter == 'string' || typeof parameter == 'number') {
      value = value.replace(/%s/g, parameter);
    } else if (typeof parameter == 'object') {
      for (let k in parameter) {
        if (typeof parameter[k] == 'string' || typeof parameter[k] == 'number') {
          value = value.replace(new RegExp(`{{${k}}}`, 'g'), parameter[k]);
        }
      }
    }
    return value;
  } catch (err) {
    return key;
  }
};
