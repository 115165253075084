/**
 * account server
 * admin的权限，账户管理相关
 */
import request from '../utils/gsrequest';
import { getConfig } from './server-url-config/env';

const urlConfig = getConfig();
const serviceUrl = `${urlConfig.apiUrl.prefix}${urlConfig.apiUrl.auth}`;

function requestWrapGet(path, options, redirect) {
  return request(serviceUrl + path, { method: 'GET', ...options }, redirect);
}

function requestWrapPost(path, options, redirect) {
  return request(serviceUrl + path, { method: 'POST', body: {}, ...options }, redirect);
}

export async function accountLogin(querys) {
  return requestWrapPost(`/login`, { method: 'POST', body: querys, mode: 'cors' });
}

export async function accountInfo(payload) {
  return requestWrapGet(`/account/info`, payload);
}

export async function addAccountRobotRelation(querys) {
  return requestWrapPost(`/account_robot_relation/add`, { method: 'POST', body: querys, mode: 'cors' });
}

export async function addAccount(querys) {
  return requestWrapPost(`/account/upsert`, { method: 'POST', body: querys });
}

export const uploadError = () => {};
