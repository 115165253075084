import request from "src/utils/request";
import { getConfig } from './server-url-config/env';

const urlConfig = getConfig();
const unitUrl = `${urlConfig.apiUrl.prefix}/api-gateway/account/measureType`;

export async function getUnit() {
  return request(unitUrl, { method: 'GET', mode: 'cors' }, false);
};

export async function setUnit(unit) {
  return request(`${unitUrl}/${unit}`, { method: 'PUT', mode: 'cors' }, false)
};
