import { message } from 'antd';

export function showErrorCodesMessage(code) {
  switch (code) {
    case -10000:
      message.error(i('角色名不可为空'));
      break;
    case -10001:
      message.error(i('角色名称需要在2-10个字符以内'));
      break;
    case -10033:
      message.error(i('存在关联用户,无法修改角色名'));
      break;
    default:
      break;
    // default:
    // message.error(i(`未知错误`));
  }
}
