import { getUserPermission as get } from '../../common/gobalState';

export const getUserPermission = get;

export function PermissionCheck(code) {
  return function _PermissionCheck(comp) {
    return getUserPermission()[code] ? comp : null;
  };
}

export function hasPermission(code) {
  return getUserPermission()[code];
}
