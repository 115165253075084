import { message } from "antd";
import { i18n as i } from 'src/i18n';
// @ts-ignore
import { Action, useRegisterActions } from "kbar";
import { CustomerType } from 'src/services/types/cedar/tenant';
import { PredefinedRole } from "src/services/types/cedar/role";
import { getUserRole, getUserCustomerType } from 'src/utils/tenantContext';
// @ts-ignore
import { getUserPermission } from '../../../common/gobalState';

const actionsList = [
  {
    id: "sn",
    name: i("机器人详情"),
    shortcut: i("kbar_sn").split('_')[0] ? i("kbar_sn").split('_') : [],
    keywords: i("kbar_sn"),
    section: "Navigation",
    perform: () => {
      message.warning(i('输入格式不正确，请重新输入'));
    },
    subtitle: i("输入<字母SN + 空格 + sn号>快速跳转至机器人详情页"),
    permissionCode: 'Robot.Manager'
  },
  {
    id: "laserActivation",
    name: i("激光激活"),
    shortcut: i("kbar_laserActivation").split('_')[0] ? i("kbar_laserActivation").split('_') : [],
    keywords: i("kbar_laserActivation"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/robotDeploy/laserActive';
    },
    subtitle: i("选中并回车快速跳转至") + i("激光激活"),
    permissionCode: 'Deploy.LaserActivation'
  },
  {
    id: "robotManagement",
    name: i("机器人管理"),
    shortcut: i("kbar_robotManagement").split('_')[0] ? i("kbar_robotManagement").split('_') : [],
    keywords: i("kbar_robotManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/robot/manager'
    },
    subtitle: i("选中并回车快速跳转至") + i("机器人管理"),
    permissionCode: 'Robot.Manager'
  },
  {
    id: "taskManagement",
    name: i("任务管理"),
    shortcut: i("kbar_taskManagement").split('_')[0] ? i("kbar_taskManagement").split('_') : [],
    keywords: i("kbar_taskManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/robot/task'
    },
    subtitle: i("选中并回车快速跳转至") + i("任务管理"),
    permissionCode: 'Robot.Task'
  },
  {
    id: "recordSharingHistory",
    name: i("记录分享历史"),
    shortcut: i("kbar_recordSharingHistory").split('_')[0] ? i("kbar_recordSharingHistory").split('_') : [],
    keywords: i("kbar_recordSharingHistory"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/robot/shared-record-history'
    },
    subtitle: i("选中并回车快速跳转至") + i("记录分享历史"),
    permissionCode: 'EventCenter'
  },
  //todo 电梯功能被重新定义。
  
  // {
  //   id: "elevatorManagement",
  //   name: i("电梯管理"),
  //   shortcut: i("kbar_elevatorManagement").split('_')[0] ? i("kbar_elevatorManagement").split('_') : [],
  //   keywords: i("kbar_elevatorManagement"),
  //   section: "Navigation",
  //   perform: () => {
  //     window.location.href = window.location.origin + '/#/elevator/manager'
  //   },
  //   subtitle: i("选中并回车快速跳转至") + i("电梯管理"),
  //   permissionCode: 'Elevator.Manager'
  // },
  {
    id: "eventDefinition",
    name: i("事件定义"),
    shortcut: i("kbar_eventDefinition").split('_')[0] ? i("kbar_eventDefinition").split('_') : [],
    keywords: i("kbar_eventDefinition"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/event/definition'
    },
    subtitle: i("选中并回车快速跳转至") + i("事件定义"),
    permissionCode: 'EventCenter.Definition'
  },
  {
    id: "languageManagement",
    name: i("事件多语言管理"),
    shortcut: i("kbar_languageManagement").split('_')[0] ? i("kbar_languageManagement").split('_') : [],
    keywords: i("kbar_languageManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/event/multi-language'
    },
    subtitle: i("选中并回车快速跳转至") + i("事件多语言管理"),
    permissionCode: 'EventCenter.MultilingualManager'
  },
  {
    id: "eventManagement",
    name: i("事件管理"),
    shortcut: i("kbar_eventManagement").split('_')[0] ? i("kbar_eventManagement").split('_') : [],
    keywords: i("kbar_eventManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/event/event-record'
    },
    subtitle: i("选中并回车快速跳转至") + i("事件管理"),
    permissionCode: 'EventCenter.Manager'
  },
  {
    id: "eventConfiguration",
    name: i("事件配置"),
    shortcut: i("kbar_eventConfiguration").split('_')[0] ? i("kbar_eventConfiguration").split('_') : [],
    keywords: i("kbar_eventConfiguration"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/event/event-config'
    },
    subtitle: i("选中并回车快速跳转至") + i("事件配置"),
    permissionCode: 'EventCenter'
  },
  {
    id: "sendTemplate",
    name: i("发送模板"),
    shortcut: i("kbar_sendTemplate").split('_')[0] ? i("kbar_sendTemplate").split('_') : [],
    keywords: i("kbar_sendTemplate"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/event/send-template'
    },
    subtitle: i("选中并回车快速跳转至") + i("发送模板"),
    permissionCode: 'EventCenter.SendTemplateConfig'
  },
  {
    id: "productManagement",
    name: i("产品管理"),
    shortcut: i("kbar_productManagement").split('_')[0] ? i("kbar_productManagement").split('_') : [],
    keywords: i("kbar_productManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/system/product'
    },
    subtitle: i("选中并回车快速跳转至") + i("产品管理"),
    permissionCode: 'System.Product'
  },
  {
    id: "accountManagement",
    name: i("账号管理"),
    shortcut: i("kbar_accountManagement").split('_')[0] ? i("kbar_accountManagement").split('_') : [],
    keywords: i("kbar_accountManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/system/account'
    },
    subtitle: i("选中并回车快速跳转至") + i("账号管理"),
    permissionCode: 'System.Account'
  },
  {
    id: "authorityManagement",
    name: i("权限管理"),
    shortcut: i("kbar_authorityManagement").split('_')[0] ? i("kbar_authorityManagement").split('_') : [],
    keywords: i("kbar_authorityManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/system/permission'
    },
    subtitle: i("选中并回车快速跳转至") + i("权限管理"),
    permissionCode: 'System.Permission'
  },
  {
    id: "firmwareManagement",
    name: i("升级管理-固件管理"),
    shortcut: i("kbar_firmwareManagement").split('_')[0] ? i("kbar_firmwareManagement").split('_') : [],
    keywords: i("kbar_firmwareManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/upgrade/firmware-manager'
    },
    subtitle: i("选中并回车快速跳转至") + i("升级管理-固件管理"),
    permissionCode: 'System.Firmware'
  },
  {
    id: "systemManagementFirmwareManagement",
    name: i("系统管理-固件管理"),
    shortcut: i("kbar_systemManagement_firmwareManagement").split('_')[0] ? i("kbar_systemManagement_firmwareManagement").split('_') : [],
    keywords: i("kbar_systemManagement_firmwareManagement"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/system/firmware'
    },
    subtitle: i("选中并回车快速跳转至") + i("系统管理-固件管理"),
    permissionCode: 'System.Firmware'
  },
  {
    id: "robotUpgrade",
    name: i("机器人升级"),
    shortcut: i("kbar_robotUpgrade").split('_')[0] ? i("kbar_robotUpgrade").split('_') : [],
    keywords: i("kbar_robotUpgrade"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/upgrade/robot-upgrade'
    },
    subtitle: i("选中并回车快速跳转至") + i("机器人升级"),
    permissionCode: 'Upgrade.RobotUpgrade'
  },
  {
    id: "upgradeHistory",
    name: i("升级历史"),
    shortcut: i("kbar_upgradeHistory").split('_')[0] ? i("kbar_upgradeHistory").split('_') : [],
    keywords: i("kbar_upgradeHistory"),
    section: "Navigation",
    perform: () => {
      window.location.href = window.location.origin + '/#/upgrade/ota-upgrade'
    },
    subtitle: i("选中并回车快速跳转至") + i("升级历史"),
    permissionCode: 'Upgrade.History'
  },
];

export function useInitialActions() {
  const permission: { [key: string]: boolean } | {} = getUserPermission();
  const isSuperAdmin = (name: string) => {
    const superAdminEffectList = [
      '事件定义',
      '事件多语言管理',
      '事件配置',
      '发送模板',
    ];

    return getUserRole() === PredefinedRole.SUPER_ADMINISTRATOR && superAdminEffectList.includes(name);
  }

  const isOperator = (name: string) => {
    const operatorEffectList = [
      '事件中心',
      '记录分享历史',
      '记录列表',
      '事件管理'
    ];

    return getUserCustomerType() === CustomerType.OPERATOR && operatorEffectList.includes(name);
  }

  const tempActions: Action[] = []
  actionsList.forEach(ele => {
    if (!ele.permissionCode ||  permission[ele.permissionCode as keyof typeof permission] || isSuperAdmin(ele.name) || isOperator(ele.name)) {
      tempActions.push({
        id: ele.id,
        name: ele.name,
        shortcut: ele.shortcut,
        keywords: ele.keywords,
        section: ele.section,
        perform: ele.perform,
        subtitle: ele.subtitle,
      });
    }
  })
  useRegisterActions(tempActions)
}
